import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import '../../language/i18n.js';
import CatchFruits from './CatchFruits.js';
import Fruitzzle from './Fruitzzle.js';
import './Games.css';
import MemoryFruits from './MemoryFruits.js';
import memoryfruitsImg from '../../assets/games/memoryfruits.png';
import fruitzzleImg from '../../assets/games/fruitzzle.png';
import bombfruitImg from '../../assets/games/bombfruit.png';
import Bombfruit from './Bombfruit.js';

/**
 * The Games component
 * @param {Object} props The props passed to the component
 * @param {Object} props.user The user object
 * @param {Object} props.notification The notification object
 * @param {Function} props.setNotification The function to set the notification
 * @param {Boolean} props.loading The flag to show the loading icon
 * @param {Function} props.setLoading The function to set the loading flag
 * @param {Function} props.handleLogout The function to handle the logout
 */
function Games({ user, notification, setNotification, loading, setLoading, handleLogout }) {
  /**
   * The game types
   */
  const GAME_TYPES = {
    MEMORY_FRUITS: 'memoryfruits',
    CATCH_FRUITS: 'catchfruits',
    FRUITZZLE: 'fruitzzle',
    BOMBFRUIT: 'bombfruit',
  };

  /**
   * The state variables
   */
  const [selectedGame, setSelectedGame] = useState(null);

  /**
   * The function to select a game
   * @param {String} game The game to select
   */
  const selectGame = (game) => {
    setSelectedGame(game);
  };

  /**
   * The function to close the game
   */
  const closeGame = () => {
    setSelectedGame(null);
  };

  return (
    <div className='games'>
      {selectedGame && (
        <div className='divGameClose'>
          <FontAwesomeIcon className="gameCloseIcon" icon={faClose} onClick={closeGame} />
        </div>
      )}
      {!selectedGame && (
        <div className='head'>
          <img className='gameImg' onClick={() => selectGame(GAME_TYPES.MEMORY_FRUITS)} src={memoryfruitsImg} alt="Memory Fruits" />
          <img className='gameImg' onClick={() => selectGame(GAME_TYPES.FRUITZZLE)} src={fruitzzleImg} alt="Fruitzzle" />
          <img className='gameImg' onClick={() => selectGame(GAME_TYPES.BOMBFRUIT)} src={bombfruitImg} alt="Bombfruit" />
          {/* <img className='gameImg' onClick={() => selectGame(GAME_TYPES.CATCH_FRUITS)} src={bombfruitImg} alt="Bombfruit" /> */}
        </div>
      )}
      {selectedGame === GAME_TYPES.MEMORY_FRUITS && <MemoryFruits />}
      {selectedGame === GAME_TYPES.CATCH_FRUITS && <CatchFruits />}
      {selectedGame === GAME_TYPES.FRUITZZLE && <Fruitzzle />}
      {selectedGame === GAME_TYPES.BOMBFRUIT && <Bombfruit />}
    </div>
  );
}

export default Games;

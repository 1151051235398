import React, { useEffect, useRef, useState } from 'react';
import './Menu.css';
import fruitityIcon from '../assets/fruitity-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faBars, faChalkboardTeacher, faCoffee, faGamepad, faLock, faNewspaper, faQuestion, faSchool, faSignOutAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

/**
 * Menu component
 * @param {Object} props - Component properties
 * @param {Object} props.user - Current user object
 * @param {Array} props.classesData - Array of classes data
 * @param {Object} props.selectedClass - Currently selected class
 * @param {function} props.setSelectedClass - Function to set selected class
 * @returns {React.ReactElement} The Menu component
 */
const Menu = ({ user, classesData, selectedClass, setSelectedClass }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { logoutCurrentUser } = useAuth();
    const menuRef = useRef(null);

    /**
     * Adds an event listener to the document to listen for clicks outside of the
     * menu. If a click is detected outside of the menu, the menu is closed.
     */
    useEffect(() => {
        /**
         * Handles clicks outside of the menu
         * @param {Event} event - The click event
         */
        const handleClickOutside = (event) => {
          if (menuRef.current && !menuRef.current.contains(event.target)) {
            closeMenu();
          }
        };
      
        // Add the event listener to the document
        document.addEventListener('mousedown', handleClickOutside);
      
        // Clean up by removing the event listener when the component is unmounted
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isMenuOpen]);

    /**
     * Handles logoutCurrentUser
     */
    const handleLogout = () => {
        logoutCurrentUser();
        navigate('/login');
    };

    /**
     * Closes the menu by adding a hide animation and updating the menu state
     */
    const closeMenu = () => {
        // Get the menu options element
        const menuOptions = document.querySelector('.divMenuNavOptions');
        
        // If the menu options element exists, proceed to hide it
        if (menuOptions) {
            // Add the class to trigger the hide animation
            menuOptions.classList.add('divMenuNavOptionsHide');

            // After a delay, remove the hide class and update the menu state
            setTimeout(() => {
                menuOptions.classList.remove('divMenuNavOptionsHide');
                setIsMenuOpen(false);
            }, 250);
        }
    }

    /**
     * Toggles menu open/close
     */
    const toggleMenu = () => {
        if (isMenuOpen) {
            closeMenu();
        } else {
            setIsMenuOpen(true);
        }
    };

    /**
     * Navigates to a given path
     * @param {string} path - Path to navigate to
     */
    const navigateTo = (path) => {
        navigate(path);
        toggleMenu();
    };

    /**
     * Navigates to a class
     * @param {Object} classData - Class data
     */
    const navigateToClass = (classData) => {
        navigate(`/classes/${classData._id}`);
        setSelectedClass(classData);
        toggleMenu();
    };

    return (
        <div className='divMenu'>
            <nav className='menuNav'>
                <div className='menuNavTitle'>
                    <div className='menuNavTitleLeft'>
                        {user?.news?.filter(n => !n.readed).length > 0 && (
                            <p className='menuItemNumberNews menuItemNumberNewsBar'>
                                {user.news.filter(n => !n.readed).length}
                            </p>
                        )}
                        <FontAwesomeIcon className="menuIcon" icon={faBars} onClick={toggleMenu} />
                        <img alt="fruitityicon" className="menuLogo" src={fruitityIcon} />
                        <h1 className='menuTitle'>Fruitity</h1>
                    </div>
                    {selectedClass && pathname.startsWith('/classes') && (
                        <h3 className="menuClassName">{selectedClass.name}</h3>
                    )}
                </div>
            </nav>

            {isMenuOpen && (
                <div ref={menuRef} className='divMenuNavOptions'>
                    <div className="menuIconLink" onClick={() => navigateTo('/classes')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faSchool} />
                        <p className='menuIconText'>{t('classes')}</p>
                    </div>
                    <div className='divMenuClasses'>
                        {classesData?.
                        sort((a, b) => a.name.localeCompare(b.name)).
                        filter((a) => !a.archived).
                        map((classData) => (
                            <div key={classData._id} onClick={() => navigateToClass(classData)} className="menuIconLink menuIconLinkClass">
                                <FontAwesomeIcon className="menuIconItem" icon={faChalkboardTeacher} />
                                <p className='menuIconText'>
                                    {classData.name + (classData.user_id !== user._id ? ` (${t('invited')})` : '')}
                                </p>
                            </div>
                        ))}
                    </div>
                    <div className="menuIconLink" onClick={() => navigateTo('/awards')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faAward} />
                        <p className='menuIconText'>{t('awards')}</p>
                    </div>
                    <div className="menuIconLink" onClick={() => navigateTo('/games')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faGamepad} />
                        <p className='menuIconText'>{t('games')}</p>
                    </div>
                    <div className="menuIconLink menuItemNews" onClick={() => navigateTo('/news')}>
                        {user?.news?.filter(n => !n.readed).length > 0 && (
                            <p className='menuItemNumberNews'>
                                {user.news.filter(n => !n.readed).length}
                            </p>
                        )}
                        <FontAwesomeIcon className="menuIconItem" icon={faNewspaper} />
                        <p className='menuIconText'>{t('news')}</p>
                    </div>
                    <div className="menuIconLink" onClick={() => navigateTo('/user')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faUserAlt} />
                        <p className='menuIconText'>{t('user')}</p>
                    </div>

                    <div className="menuIconLink" onClick={() => navigateTo('/buymeacoffee')}>
                        <FontAwesomeIcon className="menuIconItem" icon={faCoffee} />
                        <p className='menuIconText'>{t('buymeacoffee')}</p>
                    </div>

                    <Link to="https://fruitity.com/tutorials.html" target="_blank" className="menuLink">
                        <div className="menuIconLink">
                            <FontAwesomeIcon className="menuIconItem" icon={faQuestion} />
                            <p className='menuIconText'>{t('tutorial')}</p>
                        </div>
                    </Link>
                    <div className='menuIconLink' onClick={handleLogout}>
                        <FontAwesomeIcon className="menuIconItem" icon={faSignOutAlt} />
                        <p className='menuIconText'>{t('logout')}</p>
                    </div>
                    {user.isAdmin && (
                        <div className='menuIconLink' onClick={() => navigateTo('/admin')}>
                            <FontAwesomeIcon className="menuIconItem" icon={faLock} />
                            <p className='menuIconText'>{t('admin')}</p>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Menu;

import React from 'react';
import './Cookies.css';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';


/**
 * The Cookies component shows a message to the user about the cookies
 * policy of the application. The user can choose to accept or decline
 * the cookies.
 * @param {function} setShowCookies - Function to set the state of the cookies message
 * @param {function} acceptCookies - Function to accept the cookies
 * @param {function} handleLogout - Function to handle logout when the user declines the cookies
 * @returns {React.ReactElement} The Cookies component
 */
function Cookies({
    setShowCookies,
    acceptCookies,
    handleLogout,
}) {
    const { t } = useTranslation();

    /**
     * Handles the function to accept the cookies
     */
    const handleAcceptCookies = () => {
        acceptCookies();
    };

    /**
     * Handles the function to decline the cookies
     */
    const handleDeclineCookies = () => {
        localStorage.clear();
        setShowCookies(true);
        handleLogout();
    };


    return (
        <div className="divCookies">
            {t("cookies-message")}
            <button className="buttonInverse" onClick={handleAcceptCookies}>
                {t("cookies-yes")}
            </button>
            <button className="buttonInverse" onClick={handleDeclineCookies}>
                {t("cookies-no")}
            </button>
        </div>
    );
}

export default Cookies;

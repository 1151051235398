import apple from '../assets/fruits/apple.png';
import banana from '../assets/fruits/banana.png';
import orange from '../assets/fruits/orange.png';
import grape from '../assets/fruits/grape.png';
import strawberry from '../assets/fruits/strawberry.png';
import watermelon from '../assets/fruits/watermelon.png';
import melon from '../assets/fruits/melon.png';
import pear from '../assets/fruits/pear.png';
import lemon from '../assets/fruits/lemon.png';
import kiwi from '../assets/fruits/kiwi.png';
import peach from '../assets/fruits/peach.png';
import pineapple from '../assets/fruits/pineapple.png';
import plum from '../assets/fruits/plum.png';
import cherry from '../assets/fruits/cherry.png';
import mango from '../assets/fruits/mango.png';
import avocado from '../assets/fruits/avocado.png';
import raspberry from '../assets/fruits/raspberry.png';
import fig from '../assets/fruits/fig.png';
import grenade from '../assets/fruits/grenade.png';
import apricot from '../assets/fruits/apricot.png';
import papaya from '../assets/fruits/papaya.png';
import coconut from '../assets/fruits/coconut.png';
import custardapple from '../assets/fruits/custardapple.png';
import khaki from '../assets/fruits/khaki.png';
import medlar from '../assets/fruits/medlar.png';
import multifruits from '../assets/fruits/multifruits.png';
import blueberries from '../assets/fruits/blueberries.png';
import fruit_default from '../assets/fruits/default.png';


const fruitInformation = [
    { _id: 1, position: 1, img: apple, title: 'apple' },
    { _id: 2, position: 2, img: banana, title: 'banana' },
    { _id: 3, position: 3, img: orange, title: 'orange' },
    { _id: 4, position: 4, img: grape, title: 'grape' },
    { _id: 5, position: 5, img: strawberry, title: 'strawberry' },
    { _id: 6, position: 6, img: watermelon, title: 'watermelon' },
    { _id: 7, position: 7, img: melon, title: 'melon' },
    { _id: 8, position: 8, img: pear, title: 'pear' },
    { _id: 9, position: 26, img: lemon, title: 'lemon' },
    { _id: 10, position: 10, img: kiwi, title: 'kiwi' },
    { _id: 11, position: 11, img: peach, title: 'peach' },
    { _id: 12, position: 12, img: pineapple, title: 'pineapple' },
    { _id: 13, position: 13, img: plum, title: 'plum' },
    { _id: 14, position: 14, img: cherry, title: 'cherry' },
    { _id: 15, position: 15, img: mango, title: 'mango' },
    { _id: 16, position: 16, img: avocado, title: 'avocado' },
    { _id: 17, position: 17, img: raspberry, title: 'raspberry' },
    { _id: 18, position: 18, img: fig, title: 'fig' },
    { _id: 19, position: 19, img: grenade, title: 'grenade' },
    { _id: 20, position: 20, img: apricot, title: 'apricot' },
    { _id: 21, position: 21, img: papaya, title: 'papaya' },
    { _id: 22, position: 22, img: coconut, title: 'coconut' },
    { _id: 23, position: 23, img: custardapple, title: 'custardapple' },
    { _id: 24, position: 24, img: khaki, title: 'khaki' },
    { _id: 25, position: 25, img: medlar, title: 'medlar' },
    { _id: 26, position: 0, img: multifruits, title: 'multifruits' },
    { _id: 27, position: 9, img: blueberries, title: 'blueberries' },

]
/**
 * Returns all the fruits in the fruitInformation array sorted by their position.
 * @returns {Object[]} The array of fruits
 */
export const getAllFruitImage = () => {
    return fruitInformation.sort((fr, fr2) => fr.position - fr2.position)
}

/**
 * Retrieves the image of a fruit based on the given fruit identifier.
 * If the fruit is not found, returns the default fruit image.
 *
 * @param {string | number} fruit - The identifier of the fruit, either as a string or number.
 * @returns {string} The image path of the fruit or the default image if not found.
 */
export const getFruitImage = (fruit) => {
    const fruitId = parseInt(fruit); // Convert the fruit identifier to an integer
    // Search for the fruit in the fruitInformation array by its ID
    return fruitInformation.find((fr) => fr._id === fruitId)?.img || fruit_default;
};

/**
 * Retrieves the name of a fruit based on its identifier.
 * If the fruit is not found, returns null.
 *
 * @param {number} id - The identifier of the fruit.
 * @returns {string | null} The name of the fruit or null if not found.
 */
export const getFruitNameById = (id) => {
    // Search for the fruit in the fruitInformation array by its ID
    const foundFruit = fruitInformation.find((fruit) => fruit._id === id);
    // Return the name of the fruit if found, otherwise return null
    return foundFruit ? foundFruit.title : null;
};



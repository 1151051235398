import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import handleResponseError, { setNewsUserAsReaded } from '../utils/APIUtils.js';

import './News.css';


/**
 * Component that renders the news of the user.
 *
 * @param {object} user - Current user object.
 * @param {object} notification - Notification object.
 * @param {function} setNotification - Function to set notification state.
 * @param {boolean} loading - Loading state.
 * @param {function} setLoading - Function to set loading state.
 * @param {function} handleLogout - Function to handle logout.
 */
function News({ user, notification, setNotification, loading, setLoading, handleLogout }) {
  const { t } = useTranslation();
  const hasRun = useRef(false);

  const [initialNews] = useState([...user?.news]);

  /**
   * Function to mark all user's news as readed.
   */
  const handleMarkNewsAsRead = async () => {
    if (user?.news?.some((newsItem) => !newsItem.readed)) {
      setLoading(true);

      // Call the API to mark all user's news as readed.
      const response = await setNewsUserAsReaded(user.token, user);

      if (response && response.ok) {
        // Update the local user object to set all news as readed
        user.news = user.news.map((newsItem) => ({
          ...newsItem,
          readed: true,
        }));
      } else {
        // If the response is not ok, handle the error.
        handleResponseError(response, t, handleLogout, setNotification);
      }

      setLoading(false);
    }
  };

  useEffect(() => {
    // Call the function to mark all user's news as readed on mount.
    if(!hasRun.current) {
      hasRun.current = true;
      handleMarkNewsAsRead();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="divNews">
      {initialNews &&
        initialNews.sort((a, b) => new Date(b.creationDate) - new Date(a.creationDate))
          .map((newsItem) => (
            <div
              className={`divNew ${!newsItem.readed ? 'divNewIsNew' : ''}`}
              key={newsItem._id}
            >
              <h1>{newsItem[`title${user.language.toUpperCase()}`]}</h1>
              <p>{newsItem[`description${user.language.toUpperCase()}`]}</p>
              {!newsItem.readed && (
                <div className="divNewIcon">{t('new')}</div>
              )}
            </div>
          ))}
    </div>
  );
};

export default News;

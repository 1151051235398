import avatar1 from '../assets/avatars/avatar1.png';
import avatar2 from '../assets/avatars/avatar2.png';
import avatar3 from '../assets/avatars/avatar3.png';
import avatar4 from '../assets/avatars/avatar4.png';
import avatar5 from '../assets/avatars/avatar5.png';
import avatar6 from '../assets/avatars/avatar6.png';
import avatar7 from '../assets/avatars/avatar7.png';
import avatar8 from '../assets/avatars/avatar8.png';
import avatar9 from '../assets/avatars/avatar9.png';
import avatar10 from '../assets/avatars/avatar10.png';
import avatar11 from '../assets/avatars/avatar11.png';
import avatar12 from '../assets/avatars/avatar12.png';
import avatar13 from '../assets/avatars/avatar13.png';
import avatar14 from '../assets/avatars/avatar14.png';

import special1 from '../assets/avatars/especial1.png';
import special2 from '../assets/avatars/especial2.png';
import special3 from '../assets/avatars/especial3.png';
import special4 from '../assets/avatars/especial4.png';
import special5 from '../assets/avatars/especial5.png';

import avatar_default from '../assets/avatars/avatar_default.png';

const avatars = [
    { img: avatar_default, title: 'avatar_default' },
    { img: avatar1, title: 'avatar1' },
    { img: avatar2, title: 'avatar2' },
    { img: avatar3, title: 'avatar3' },
    { img: avatar4, title: 'avatar4' },
    { img: avatar5, title: 'avatar5' },
    { img: avatar6, title: 'avatar6' },
    { img: avatar7, title: 'avatar7' },
    { img: avatar8, title: 'avatar8' },
    { img: avatar9, title: 'avatar9' },
    { img: avatar10, title: 'avatar10' },
    { img: avatar11, title: 'avatar11' },
    { img: avatar12, title: 'avatar12' },
    { img: avatar13, title: 'avatar13' },
    { img: avatar14, title: 'avatar14' },
    
]

const specialAvatars = [...avatars, 
    { img: special1, title: 'especial1' }, 
    { img: special2, title: 'especial2' },
    { img: special3, title: 'especial3' },
    { img: special4, title: 'especial4' },
    { img: special5, title: 'especial5' },
];

const allAvatars = [...avatars, ...specialAvatars];



/**
 * Returns the image of the avatar with the given title.
 * 
 * @param {string} avatarTitle - The title of the avatar.
 * @returns {string|undefined} The image of the avatar or undefined if not found.
 */
export const getAvatarImage = (avatarTitle) => {
    const avatar = allAvatars.find((a) => a.title === avatarTitle);
    return avatar?.img;
};

/**
 * Finds and returns the avatar object with the specified title.
 * 
 * @param {string} title - The title of the avatar to find.
 * @returns {Object|undefined} The avatar object with the given title, or undefined if not found.
 */
export const getAvatarByTitle = (title) => {
    return allAvatars.find((avatar) => avatar.title === title);
};

/**
 * Returns a list of all available avatars. If userAvatarTitles is provided, it will include those avatars as well.
 * 
 * @param {string[]} userAvatarTitles - The titles of the avatars to include.
 * @returns {Object[]} The list of all available avatars.
 */
export const getAllAvatars = (theUser = null) => {
    let resultList = [...avatars];
    if(theUser?.level > 1) {
        resultList = [...specialAvatars];
    }
    return resultList;
};

import { faCancel, faEdit, faFolder, faFolderOpen, faSave, faShareAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Modal from '../../components/Modal';
import handleResponseError, { archiveClass, editClass, inviteTeacherToClass, removeClass, removeInvitationTeacherToClass, setNotification } from '../../utils/APIUtils';
import './SetupClass.css';

/**
 * This component is used to setup a class, edit the class name and school name,
 * invite teachers to the class, delete invitations and delete the class.
 * @param {Object} props - The props of the component.
 * @param {Object} props.setSelectedClass - The function to set the selected class.
 * @param {Object} props.theClass - The class to be setup.
 * @param {Object} props.setClassesData - The function to set the classes data.
 * @param {Object} props.classesData - The classes data.
 * @param {Object} props.setNotification - The function to set the message.
 * @param {Object} props.setLoading - The function to set the loading state.
 * @param {Object} props.user - The user that is logged in.
 * @param {Object} props.handleLogout - The function to logout the user.
 */
function SetupClass({
  setSelectedClass,
  theClass,
  setClassesData,
  classesData,
  setNotification,
  setLoading,
  user,
  handleLogout,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showDeleteClassModal, setShowDeleteClassModal] = useState(false);
  const [showArchiveClassModal, setShowArchiveClassModal] = useState(false);
  const [showEditClassModal, setShowEditClassModal] = useState(false);
  const [showInviteTeacherModal, setShowInviteTeacherModal] = useState(false);
  const [showDeleteInvitationModal, setShowDeleteInvitationModal] = useState(false);

  const [invitationEmail, setInvitationEmail] = useState(null);

  /**
   * Edit the class name and school name.
   *
   * This function sends a request to the server to edit the class name and school name.
   * If the request is successful, it updates the classes data with the new class name
   * and school name.
   *
   * @param {{ name: string, school: string }} data - The new class name and school name.
   * @returns {Promise<void>}
   */
  const editClassFromUser = async ({ name, school }) => {
    setLoading(true);

    const response = await editClass(user.token, theClass._id, name, school);

    if (response?.ok) {
      const newClasses = [...classesData];
      const classIndex = newClasses.findIndex((c) => c._id === theClass._id);
      if (classIndex >= 0) {
        newClasses[classIndex].name = response.data.name;
        newClasses[classIndex].school_name = response.data.school_name;
      }
      setClassesData(newClasses);
    } else {
      handleResponseError(response, t, handleLogout, setNotification);
    }
    setLoading(false);
    setShowEditClassModal(false);
  };

  /**
   * Delete the class.
   */
  const deleteClassFromUser = async () => {
    setLoading(true);

    const response = await removeClass(user.token, theClass._id);

    if (response?.ok) {
      setSelectedClass(null);
      setClassesData(classesData.filter((c) => c._id !== theClass._id));
      navigate('/classes');
    } else {
      handleResponseError(response, t, handleLogout, setNotification);
    }

    setLoading(false);
    setShowDeleteClassModal(false);
  };

  /**
   * Archive or unarchive the class.
   */
  const archiveClassFromUser = async () => {
    setLoading(true);

    const response = await archiveClass(user.token, theClass._id, !theClass.archived);
    if (response?.ok) {
      const newClasses = [...classesData];
      const classIndex = newClasses.findIndex((c) => c._id === theClass._id);
      if (classIndex >= 0) {
        newClasses[classIndex].archived = !theClass.archived;
      }
      setClassesData(newClasses);
      setNotification({ msg: theClass.archived ? t('archived-class') : t('unarchived-class'), type: 'OK' });

    } else {
      handleResponseError(response, t, handleLogout, setNotification);
    }

    setLoading(false);
    setShowArchiveClassModal(false);
  }

  /**
   * Invite a teacher to the class.
   * @param {Object} data - The email of the teacher to invite.
   */
  const inviteTeacher = async ({ email }) => {
    setLoading(true);

    if (email === user.email) {
      setNotification({ msg: t('cannot-invite-yourself'), type: 'ERR' });
    } else {
      const response = await inviteTeacherToClass(user.token, theClass._id, email);

      if (response?.ok) {
        const newClasses = [...classesData];
        const classIndex = newClasses.findIndex((c) => c._id === theClass._id);
        if (classIndex >= 0) {
          newClasses[classIndex].usersInvited = [...newClasses[classIndex].usersInvited, email];
        }
        setClassesData(newClasses);
        setNotification({ msg: t('teacher-invited'), type: 'OK' });
      } else {
        handleResponseError(response, t, handleLogout, setNotification);
      }
    }

    setLoading(false);
    setShowInviteTeacherModal(false);
  };

  /**
   * Delete an invitation.
   */
  const deleteInvitation = async () => {
    setLoading(true);

    const response = await removeInvitationTeacherToClass(
      user.token,
      theClass._id,
      invitationEmail
    );

    if (response?.ok) {
      const newClasses = [...classesData];
      const classIndex = newClasses.findIndex((c) => c._id === theClass._id);
      if (classIndex >= 0) {
        newClasses[classIndex].usersInvited = newClasses[
          classIndex
        ].usersInvited.filter((u) => u !== invitationEmail);
      }
      setClassesData(newClasses);
      setNotification({ msg: t('teacher-uninvited'), type: 'OK' });
    } else {
      handleResponseError(response, t, handleLogout, setNotification);
    }

    setLoading(false);
    setShowDeleteInvitationModal(false);
  };

  return (
    <div>
      <div className="divSetupClassModals">
        {showDeleteClassModal && (
          <Modal
            notification={t('modal-sure-remove-class', { className: theClass.name })}
            confirmText={t('modal-btn-delete-ok')}
            cancelText={t('modal-btn-delete-cancel')}
            onConfirm={deleteClassFromUser}
            onCancel={() => setShowDeleteClassModal(false)}
            confirmIcon={faTrash}
            cancelIcon={faCancel}
          />
        )}
        {showArchiveClassModal && (
          <Modal
            notification={theClass.archived ? t('modal-sure-unarchive-class', { className: theClass.name }) : t('modal-sure-archive-class', { className: theClass.name })}
            confirmText={theClass.archived ? t('modal-btn-unarchive-ok') : t('modal-btn-archive-ok')}
            cancelText={t('modal-btn-archive-cancel')}
            onConfirm={archiveClassFromUser}
            onCancel={() => setShowArchiveClassModal(false)}
            confirmIcon={theClass.archived ? faFolderOpen : faFolder}
            cancelIcon={faCancel}
          />
        )}
        {showEditClassModal && (
          <Modal
            notification={t('modal-edit-class')}
            confirmText={t('modal-btn-edit-ok')}
            cancelText={t('modal-btn-edit-cancel')}
            onConfirm={editClassFromUser}
            onCancel={() => setShowEditClassModal(false)}
            fields={[
              {
                name: 'name',
                type: 'text',
                placeholder: t('classname'),
                mandatory: true,
                value: theClass.name,
              },
              {
                name: 'school',
                type: 'text',
                placeholder: t('schoolname'),
                mandatory: true,
                value: theClass.school_name,
              },
            ]}
            confirmIcon={faSave}
            cancelIcon={faCancel}
          />
        )}
        {showInviteTeacherModal && (
          <Modal
            notification={t('modal-invite-teacher')}
            confirmText={t('modal-btn-invite-teacher-ok')}
            cancelText={t('modal-btn-invite-teacher-cancel')}
            onConfirm={inviteTeacher}
            onCancel={() => setShowInviteTeacherModal(false)}
            fields={[
              {
                name: 'email',
                type: 'email',
                placeholder: t('email'),
                mandatory: true,
              },
            ]}
            confirmIcon={faShareAlt}
            cancelIcon={faCancel}
          />
        )}
        {showDeleteInvitationModal && (
          <Modal
            notification={t('modal-sure-remove-invitation', { email: invitationEmail })}
            confirmText={t('modal-btn-delete-ok')}
            cancelText={t('modal-btn-delete-cancel')}
            onConfirm={deleteInvitation}
            onCancel={() => setShowDeleteInvitationModal(false)}
            confirmIcon={faTrash}
            cancelIcon={faCancel}
          />
        )}
      </div>
      <div className="divSetupClass">
        <h1>{t('setup-class')}</h1>
        <div className="divSetupClassName">
          <h2>{t('classname')}</h2>
          <h2>{theClass.name}</h2>
          <h2>{t('schoolname')}</h2>
          <h2>{theClass.school_name}</h2>
        </div>
        <div className='divBtnsEditArchiveClass'>
          <button
            onClick={() => setShowEditClassModal(true)}
          >
            <FontAwesomeIcon className="iconFruitity" icon={faEdit} />
          </button>
          <button
            onClick={() => setShowArchiveClassModal(true)}
          >
            <FontAwesomeIcon className="iconFruitity" icon={theClass.archived ? faFolderOpen : faFolder} />
          </button>
        </div>
        <hr />
        <div className="divSetupClassUsersInvited">
          <h2>{t('users-invited')}</h2>
          {theClass.usersInvited &&
            theClass.usersInvited.map((userInvited) => (
              <div className="divSetupClassUserInvited" key={"uI" + userInvited}>
                <p>{userInvited}</p>
                <FontAwesomeIcon
                  onClick={() => {
                    setInvitationEmail(userInvited);
                    setShowDeleteInvitationModal(true);
                  }}
                  className="iconDeleteInvitation"
                  icon={faTrash}
                />
              </div>
            ))}
          <p className="divSetupClassUsersInvitedPInvite">
            {t('invite-user-p')}
          </p>
          <button
            onClick={() => setShowInviteTeacherModal(true)}
            className="btnSetupClassInviteTeacher"
          >
            <FontAwesomeIcon className="iconFruitity" icon={faShareAlt} />
          </button>
        </div>
        <hr />
        <div>
          <h2>{t('delete-class')}</h2>
          <button
            onClick={() => setShowDeleteClassModal(true)}
            className="btnSetupClassDeleteClass"
          >
            <FontAwesomeIcon className="iconFruitity" icon={faTrash} />
          </button>
        </div>
      </div>
    </div>
  );
}

export default SetupClass;

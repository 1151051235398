/**
 * Converts a JavaScript Date object to a MySQL formatted date string (YYYY-MM-DD).
 * 
 * @param {Date} jsDate - The JavaScript Date object to convert.
 * @returns {string} The formatted date string in MySQL format.
 */
export const getMySQLDateFromJSDate = (jsDate) => {
    // Extract day, month, and year from the JavaScript Date object
    const day = jsDate.getDate().toString().padStart(2, '0'); // Ensure day is two digits
    const month = (jsDate.getMonth() + 1).toString().padStart(2, '0'); // Ensure month is two digits
    const year = jsDate.getFullYear(); // Get the full year

    // Return the date in MySQL format (YYYY-MM-DD)
    return `${year}-${month}-${day}`;
};

/**
 * Converts a JavaScript Date object to a date string in the format DD/MM/YYYY.
 * 
 * @param {Date} date - The JavaScript Date object to convert.
 * @returns {string} The formatted date string.
 */
export const getStringDate = (date) => {
    date = new Date(date);

    // Extract day, month, and year from the JavaScript Date object
    const day = date.getDate().toString().padStart(2, '0'); // Ensure day is two digits
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure month is two digits
    const year = date.getFullYear(); // Get the full year

    // Return the date in the format DD/MM/YYYY
    return `${day}/${month}/${year}`;
};

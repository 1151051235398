import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getAllFruitImage } from '../../utils/FruitImgUtil.js';
import './Fruitzzle.css';

function Fruitzzle({ user, notification, setNotification, loading, setLoading, handleLogout }) {
  const { t } = useTranslation();

  const [started, setStarted] = useState(false);
  const [finished, setFinished] = useState(false);

  const [fruit, setFruit] = useState(null);
  const [position, setPosition] = useState(0);
  const [fruits, setFruits] = useState([]);
  const [name, setName] = useState('');
  const [randomName, setRandomName] = useState('');
  const [constructedName, setConstructedName] = useState('');


  const [indexName, setIndexName] = useState(0);
  const [hasError, setHasError] = useState(false);

  const [rightFruits, setRightFruits] = useState(0);
  const [time, setTime] = useState(0);

  const [blur, setBlur] = useState(10);
  const [gameTime, setGameTime] = useState(120);
  const [withAccents, setWithAccents] = useState(false);

  const startGame = () => {
    setStarted(true);
    setFinished(false);

    const theFruits = getAllFruitImage();
    const randomFruits = [...theFruits].sort(() => Math.random() - 0.5).slice(0, theFruits.length);
    setFruits(randomFruits);

    setTime(gameTime);
    setRightFruits(0);
    setPosition(0);
    setIndexName(0);
    setConstructedName('');
    setHasError(false);
  }

  const startAgain = () => {
    setFinished(false);
    setStarted(false);
  }

  const stopGame = () => {
    setFinished(true);
    setStarted(false);
  }

  const nextFruit = () => {
    if (position !== 0) {
      setRightFruits(rightFruits + 1);
    }
    let nextPos = position + 1;
    setPosition(nextPos);

    setFruit(fruits[nextPos]);
    let theTitle = t(fruits[nextPos].title);
    let theName = null;
    if (!withAccents) {
      //theName = theTitle.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
      theName = theTitle.replace(/[áàäâ]/g, 'a')
        .replace(/[éèëê]/g, 'e')
        .replace(/[íìïî]/g, 'i')
        .replace(/[óòöô]/g, 'o')
        .replace(/[úùüû]/g, 'u')
        .toUpperCase();
    } else {
      theName = theTitle.toUpperCase();
    }
    setName(theName);
    setRandomName(theName.split('').sort(() => Math.random() - 0.5).join(''));
    setConstructedName('');

    setIndexName(0);
    setHasError(false);
  }


  useEffect(() => {
    let timer;
    if (started && time > 0) {
      timer = setInterval(() => {
        setTime(prevTime => prevTime - 1);
      }, 1000);

    }

    if (started && time === 0) {
      stopGame();
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [started, time]);

  useEffect(() => {
    if (fruits.length > 0) {
      nextFruit();
    }
    // eslint-disable-next-line
  }, [fruits]);

  useEffect(() => {
    setBlur(10);

    const blurInterval = setInterval(() => {
      setBlur(prevBlur => Math.max(prevBlur - 0.1, 0));
    }, 100);

    return () => clearInterval(blurInterval);
    // eslint-disable-next-line
  }, [fruit]);

  const clickRandomLetter = (index) => {
    if (constructedName!== name) {

      let letterClicked = randomName.charAt(index);
      if (hasError) {
        let constNameAux = constructedName.slice(0, -1);
        constNameAux += letterClicked;
        setConstructedName(constNameAux);
      } else {
        setConstructedName(constructedName + letterClicked);
      }

      if (letterClicked === name.charAt(hasError ? constructedName.length - 1 : constructedName.length)) {
        setIndexName(indexName + 1);
        setHasError(false);
      } else {
        setHasError(true);
      }
    }
  }


  const handleGameTimeChange = (event) => {
    const newTime = parseInt(event.target.value);
    setGameTime(newTime);
  };

  const handleAccentsChange = (event) => {
    setWithAccents(event.target.checked);
  };

  return (
    <div className='fruitzzle'>
      {
        !started &&
        <div className='pregame'>
          <p className='bold'>{t('fruitzzle_intro')}</p>
          <div className='fruitzzle_div_input'>
            <p>{t('fruitzzle_time')}</p>
            <input type="number" value={gameTime} onChange={handleGameTimeChange} />
          </div>
          <div className='fruitzzle_div_input'>
            <p>{t('fruitzzle_accents')}</p>
            <input type="checkbox" value={withAccents} onChange={handleAccentsChange} />
          </div>
          <button onClick={() => startGame()}>{t('start')}</button>
        </div>
      }

      {started && !finished &&
        <button onClick={() => startAgain()}>{t('start_again')}</button>
      }

      {finished && !started &&
        <div className='fruitzzle_score_final_div'>
          {t('you_reached')}
          <div className='fruitzzle_score_final'>{rightFruits}</div>
        </div>
      }

      {
        started &&
        <div className='fruitzzle_gameboard'>
          <div className='fruitzzle_score_time'>
            <div className='fruitzzle_score'>
              {rightFruits}
            </div>
            <div className='fruitzzle_time'>
              {Math.floor(time / 60)}:{(time % 60).toString().padStart(2, '0')}
            </div>
          </div>
          <div className='fruitzzle_div_letters'>
            {
              randomName.split('').map((letter, index) => (
                <div key={index}
                  className='fruitzzle_letter_random'
                  onClick={() => clickRandomLetter(index)}>
                  {letter}
                </div>
              ))
            }
          </div>
          {fruit &&
            <img src={fruit.img} alt={"fruit"}
              style={{
                filter: `blur(${blur}px)`, // Aplica el blur dinámicamente
                transition: "filter 0.1s", // Suaviza la transición
              }} />
          }

          <div className='fruitzzle_div_letters'>
            {
              name.split('').map((letter, index) => (
                <div key={index}
                  className={`fruitzzle_letter_name ${constructedName.charAt(index) === name.charAt(index) && 'fruitzzle_letter_correct'} ${constructedName.charAt(index) !== '' && constructedName.charAt(index) !== name.charAt(index) && 'fruitzzle_letter_wrong'}`}
                >
                  {constructedName.charAt(index)}
                </div>
              ))
            }
          </div>
          {
            constructedName === name &&
            <button onClick={() => nextFruit()}>{t('next')}</button>
          }
        </div>

      }
    </div>
  );
};

export default Fruitzzle;

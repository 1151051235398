import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './ClassView.css';

import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getAllFruitImage } from '../../utils/FruitImgUtil';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAward, faChartBar, faCog, faPlus, faSchool, faUser, faUserGraduate, faUsers } from '@fortawesome/free-solid-svg-icons';

import handleResponseError, { addAwardToGroup, addAwardToStudent, addStudentsToClass, removeMealFromUser } from '../../utils/APIUtils.js';
import Calendar from '../../components/Calendar';
import Modal from '../../components/Modal';
import NewStudents from './NewStudents';
import StudentBox from '../students/StudentBox';
import StatsView from '../stats/StatsView';
import { getMySQLDateFromJSDate } from '../../utils/DateUtils';
import StudentView from '../students/StudentView';
import SetupClass from './SetupClass';


function ClassView({ setSelectedClass, classesData, user, setClassesData, notification, setNotification, loading, setLoading, awards, handleLogout }) {
  const { t } = useTranslation();
  const { id } = useParams();

  const VIEWS = {
    CLASS: 'CLASS',
    AWARD: 'AWARD',
    STATS: 'STATS',
    STUDENT: 'STUDENT',
    ADDSTUDENTS: 'ADDSTUDENTS',
    SETUP: 'SETUP'
  };

  const [view, setView] = useState(VIEWS.CLASS);
  const theClass = classesData.find((cl) => cl._id === id);
  const [date, setDate] = useState(new Date());
  const [students, setStudents] = useState([{ name: '' }]);
  const [selectedFruit, setSelectedFruit] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState(null);
  const [selectedAward, setSelectedAward] = useState(null);
  const [showModalDeleteMeal, setShowModalDeleteMeal] = useState(false);
  const [showModalAddIndividualAward, setShowModalAddIndividualAward] = useState(false);
  const [showModalAddGroupAward, setShowModalAddGroupAward] = useState(false);

  const divOverflowRef = useRef(null);
  const [divOverflowHeight, setDivOverflowHeight] = useState(100);

  useEffect(() => {
    setView(VIEWS.CLASS);
    setSelectedStudent(null);
    // eslint-disable-next-line
  }, [theClass._id]);

  useEffect(() => {
    const recalculateHeight = () => {
      if (divOverflowRef.current) {
        const windowHeight = window.innerHeight;
        const elementTop = divOverflowRef.current.getBoundingClientRect().top;
        const calculatedHeight = windowHeight - elementTop;
        setDivOverflowHeight(calculatedHeight);
      }
    };

    recalculateHeight();
    window.addEventListener('resize', recalculateHeight);
    return () => {
      window.removeEventListener('resize', recalculateHeight);
    };
  })

  const handleFruitClick = (fruit) => {
    setSelectedFruit(fruit?._id === selectedFruit?._id ? null : fruit);
  }

  const handleAwardClick = (award) => {
    if (award.type === 'G') {
      const hasStudentWithFewerPoints = theClass.students.some(student => {
        let awardPoints = student.awards.reduce((sum, aw) => sum + aw.points, 0);
        let totalPoints = student.meals.reduce((sum, m) => sum + m.points, 0);
        let totalStudentPoints = totalPoints - awardPoints;
        return totalStudentPoints < award.points;
      });
      
      if (hasStudentWithFewerPoints) {
        setNotification({ msg: t('no-enough-points-some-student'), type: 'ERR' });
      } else {
        setSelectedAward(award === selectedAward ? null : award);
        setShowModalAddGroupAward(true);
      }
    } else {
      //Else means that award type is Individual
      setSelectedAward(award === selectedAward ? null : award);
    }
  }


  const handleAddStudentsToClass = async () => {
    const filteredStudents = students.filter((student) => student.name !== '');

    if (filteredStudents.length > 0) {
      setLoading(true);

      try {
        const response = await addStudentsToClass(user.token, theClass._id, filteredStudents);

        if (response?.ok) {
          const updatedClassesData = [...classesData];
          const updatedClass = updatedClassesData.find((c) => c._id === theClass._id);

          if (updatedClass) {
            updatedClass.students = response.data.students;
          }

          setClassesData(updatedClassesData);
        } else {
          handleResponseError(response, t, handleLogout, setNotification);
        }
      } finally {
        setLoading(false);
        setView(VIEWS.CLASS);
        setStudents([{ name: '' }]);
      }
    }
  };

  const fruits = getAllFruitImage();

  const addAwardToStudentModal = async () => {
    setLoading(true);
    const response = await addAwardToStudent(user.token, theClass._id, getMySQLDateFromJSDate(date), selectedStudent?._id, selectedAward?._id, selectedAward?.title, selectedAward?.points);

    if (response?.ok) {
      setClassesData(
        classesData.map((c) => (c._id === theClass._id ? response.data : c))
      );
    } else {
      handleResponseError(response, t, handleLogout, setNotification);
    }

    setLoading(false);
    setShowModalAddIndividualAward(false);
  };

  const cancelAddIndividualAward = () => {
    setShowModalAddIndividualAward(false);
  }

  const addAwardToGroupModal = async () => {
    setLoading(true);
    const response = await addAwardToGroup(user.token, theClass._id, getMySQLDateFromJSDate(date), selectedAward?._id, selectedAward?.title, selectedAward?.points);
    
    if (response?.ok) {
      setClassesData(
        classesData.map((c) => (c._id === theClass._id ? response.data : c))
      );
    } else {
      handleResponseError(response, t, handleLogout, setNotification);
    }

    setLoading(false);
    setShowModalAddGroupAward(false);
    setSelectedAward(null);
  };

  const cancelAddGroupAward = () => {
    setSelectedAward(null);
    setShowModalAddGroupAward(false);
  }

  const onClickOkModalDeleteMeal = () => {
    deleteMeal(selectedStudent, selectedMeal);
  }

  const cancelRemoveMeal = () => {
    setShowModalDeleteMeal(false);
  }

  const deleteMeal = async (student, meal) => {
    setLoading(true);

    const response = await removeMealFromUser(user.token, theClass._id, student._id, meal._id);

    if (response?.ok) {
      const mealElement = document.getElementById(`meal${meal._id}`);
      mealElement.classList.add('divMeal-remove');
      setTimeout(() => {
        setClassesData(classesData.map((c) => c._id === theClass._id ? response.data : c));
      }, 500);
    } else {
      handleResponseError(response, t, handleLogout, setNotification);
    }

    setLoading(false);
    setShowModalDeleteMeal(false);
    setSelectedStudent(null);
    setSelectedMeal(null);
  };

  const setShowViewStudent = () => {
    setView(VIEWS.STUDENT);
  }

  return (
    <div className='divClassView'>

      {/******* MODAL DELETE MEAL *******/}
      {showModalDeleteMeal && <Modal
        notification={t('modal-sure-remove-meal')}
        confirmText={t('modal-btn-delete-ok')}
        cancelText={t('modal-btn-delete-cancel')}
        onConfirm={onClickOkModalDeleteMeal}
        onCancel={cancelRemoveMeal} />}

      {/******* MODAL ADD INDIVIDUAL AWARD *******/}
      {showModalAddIndividualAward && <Modal
        notification={t('modal-sure-add-award', { awardTitle: (selectedAward ? selectedAward.title : '-'), studentName: (selectedStudent ? selectedStudent.name : '-') })}
        confirmText={t('modal-btn-add-award-ok')}
        cancelText={t('modal-btn-add-award-cancel')}
        onConfirm={addAwardToStudentModal}
        onCancel={cancelAddIndividualAward} />}

      {/******* MODAL ADD GROUP AWARD *******/}
      {showModalAddGroupAward && <Modal
        notification={t('modal-sure-add-group-award', { awardTitle: (selectedAward ? selectedAward.title : '-') })}
        confirmText={t('modal-btn-add-award-ok')}
        cancelText={t('modal-btn-add-award-cancel')}
        onConfirm={addAwardToGroupModal}
        onCancel={cancelAddGroupAward} />}

      <div className='divClassViewFirstRow'>
        <div className='divClassViewFirstRowFirstCol'>

          {/* VIEW CLASS  BTN */}
          <div onClick={() => setView(VIEWS.CLASS)} className={`iconOperation ${view === VIEWS.CLASS && 'iconOperationSelected'}`}>
            <FontAwesomeIcon icon={faSchool} />
          </div>
          {/* VIEW AWARDS  BTN */}
          {theClass.user_id === user._id &&
            <div onClick={() => setView(VIEWS.AWARD)} className={`iconOperation ${view === VIEWS.AWARD && 'iconOperationSelected'}`}>
              <FontAwesomeIcon icon={faAward} />
            </div>
          }
          {/* VIEW STATS  BTN */}
          <div onClick={() => setView(VIEWS.STATS)} className={`iconOperation ${view === VIEWS.STATS && 'iconOperationSelected'}`}>
            <FontAwesomeIcon icon={faChartBar} />
          </div>
          {/* ADD STUDENT BTN */}
          {theClass.user_id === user._id &&
            <div onClick={() => setView(VIEWS.ADDSTUDENTS)} className={`iconOperation ${view === VIEWS.ADDSTUDENTS && 'iconOperationSelected'}`}>
              <FontAwesomeIcon icon={faPlus} />
              <FontAwesomeIcon icon={faUserGraduate} />
            </div>
          }
          {/* VIEW SETUP CLASS BTN */}
          {theClass.user_id === user._id &&
            <div onClick={() => setView(VIEWS.SETUP)} className={`iconOperation ${view === VIEWS.SETUP && 'iconOperationSelected'}`}>
              <FontAwesomeIcon icon={faCog} />
            </div>
          }
        </div>

        {(view === VIEWS.CLASS || view === VIEWS.STATS) && <div>
          {/* CALENDAR */}
          <Calendar date={date} setDate={setDate} />
        </div>}
      </div>


      {/* FRUIT PICKER */}
      {view === VIEWS.CLASS && <div className="divPickFruit">
        {/*<div className='divFruits'>*/}
        {
          fruits && fruits.map((fr) => (
            <div className='divPickFruitImg' key={fr._id}>
              <img src={fr.img}
                className={selectedFruit && (selectedFruit._id === fr._id) ? 'imgFruitPicker selectedFruit' : 'imgFruitPicker'}
                onClick={() => handleFruitClick(fr)}
                style={{ title: { color: "red" } }}
                alt={"fruit"}
              />
            </div>
          ))
        }
      </div>
      }

      {/* AWARD PICKER */}
      {view === VIEWS.AWARD && <div className="divPickAward">
        {
          awards && awards.sort((aw1, aw2) => aw1['points'] - aw2['points']).map((aw) => (
            <div onClick={() => handleAwardClick(aw)}
              className={`divAward ${selectedAward === aw && 'divAwardSelected'}`}
              key={aw._id}>
              <FontAwesomeIcon icon={faAward} className='awardIcon' />
              <h3 className='awardTitle'>{aw.default ? t(aw.title) : aw.title}</h3>
              <h3 className='awardPoints'>{aw.points}</h3>
              {aw.type === 'I' && <FontAwesomeIcon icon={faUser} className='awardTypeIcon' />}
              {aw.type === 'G' && <FontAwesomeIcon icon={faUsers} className='awardTypeIcon' />}

            </div>
          ))
        }

        {
          <Link to="/awards">
            <FontAwesomeIcon icon={faAdd} className='addAwardIcon' />
          </Link>
        }
      </div>
      }


      <div ref={divOverflowRef} className="divOverflow"
        style={{ height: `${divOverflowHeight}px`, }}>

        { /***** ADD STUDENTS VIEW ******/}
        {view === VIEWS.ADDSTUDENTS &&
          <div className='divViewAddStudents'>
            <NewStudents
              students={students}
              setStudents={setStudents}
            />
            <button onClick={handleAddStudentsToClass}>
              {/*  {t('add-students-btn')} */}
              <FontAwesomeIcon className='iconFruitity' icon={faAdd} />

            </button>
          </div>
        }

        { /***** STUDENT VIEW ******/}
        {view === VIEWS.STUDENT &&
          <StudentView
            student={selectedStudent}
            setView={setView}
            viewClass={VIEWS.CLASS}
            setLoading={setLoading}
            setSelectedStudent={setSelectedStudent}
            user={user}
            theClass={theClass}
            handleLogout={handleLogout}
            setNotification={setNotification}
            classesData={classesData}
            setClassesData={setClassesData}
          />
        }

        {/****** STUDENT LIST ******/}
        {(view === VIEWS.CLASS || view === VIEWS.AWARD)
          &&
          (!theClass.students || theClass.students.length === 0)
          &&
          <h2 className='h2NoStudents'>{t('no-students')}</h2>}

        {(view === VIEWS.CLASS || view === VIEWS.AWARD) && theClass && theClass.students.sort((a, b) => a.name.localeCompare(b.name)).map((st) => (
          <StudentBox
            key={st._id}
            date={date}
            student={st}
            setSelectedStudent={setSelectedStudent}
            loading={loading}
            setLoading={setLoading}
            notification={notification}
            setNotification={setNotification}
            theClass={theClass}
            classesData={classesData}
            setClassesData={setClassesData}
            selectedAward={selectedAward}
            setSelectedAward={setSelectedAward}
            selectedFruit={selectedFruit}
            setSelectedFruit={setSelectedFruit}
            user={user}
            setShowViewStudent={setShowViewStudent}
            deleteMeal={deleteMeal}
            setSelectedMeal={setSelectedMeal}
            setShowModalAddIndividualAward={setShowModalAddIndividualAward}
            setShowModalDeleteMeal={setShowModalDeleteMeal}
            classView={view === VIEWS.CLASS}
            awardsView={view === VIEWS.AWARD}
            handleLogout={handleLogout}
          />
        ))}


        {/****** STATS VIEW ******/}
        {
          (view === VIEWS.STATS) &&
          <StatsView
            date={date}
            theClass={theClass} />
        }

        {/****** SETUP CLASS VIEW ******/}
        {
          (view === VIEWS.SETUP) &&
          <SetupClass
            theClass={theClass}
            setClassesData={setClassesData}
            classesData={classesData}
            setNotification={setNotification}
            setLoading={setLoading}
            user={user}
            handleLogout={handleLogout}
            setSelectedClass={setSelectedClass}
          />
        }

      </div>


    </div >
  );
};

export default ClassView;

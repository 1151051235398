import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './NewClass.css';
import NewStudents from './NewStudents';
import { useNavigate } from 'react-router-dom';
import handleResponseError, { addClass } from '../../utils/APIUtils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

/**
 * The NewClass component is used to create a new class.
 *
 * @param {Object} user - The user data.
 * @param {Object} notification - The notification data.
 * @param {Function} setNotification - The function to set the notification data.
 * @param {Array} schools - The list of schools.
 * @param {Function} setSchools - The function to set the schools data.
 * @param {Boolean} loading - The boolean to indicate if the component is loading.
 * @param {Function} setLoading - The function to set the loading state.
 * @param {Array} classesData - The classes data.
 * @param {Function} setClassesData - The function to set the classes data.
 * @param {Function} handleLogout - The function to handle the user logout.
 */
function NewClass({
  user,
  notification,
  setNotification,
  schools,
  setSchools,
  loading,
  setLoading,
  classesData,
  setClassesData,
  handleLogout,
}) {
  const { t } = useTranslation();

  /**
   * The state to store the new class name.
   */
  const [newClassName, setNewClassName] = useState('');

  /**
   * The state to store the new school name.
   */
  const [newSchoolName, setNewSchoolName] = useState('');

  /**
   * The state to store the new student list.
   */
  const [newStudentList, setNewStudentList] = useState([{ name: '' }]);

  /**
   * The navigate function is used to navigate to another page.
   */
  const navigate = useNavigate();

  /**
   * The function handleCreateClass is used to create a new class and add it to the classes data.
   */
  const handleCreateClass = async () => {
    const filteredStudentList = newStudentList.filter((student) => student.name !== '');

    setLoading(true);

    const response = await addClass(
      user.token,
      newSchoolName,
      newClassName,
      filteredStudentList
    );

    if (response && response.ok) {
      const updatedClasses = [...classesData];
      updatedClasses.push(response.data);
      setClassesData(updatedClasses);
    } else {
      handleResponseError(response, t, handleLogout, setNotification);
    }

    setLoading(false);
    setNewStudentList([{ name: '' }]);
    navigate('/classes');
  };

  return (
    <div className='divNewClass'>
      <h1>{t('new-class-title')}</h1>

      <div>
        <label>{t('schoolname') + ':'}</label>
        <input
          type="text"
          id="schoolname"
          name="schoolname"
          value={newSchoolName}
          placeholder={t('schoolname')}
          onChange={(event) => setNewSchoolName(event.target.value)}
        />
      </div>

      <div>
        <label>{t('classname') + ':'}</label>
        <input
          type="text"
          id="classname"
          name="classname"
          value={newClassName}
          placeholder={t('classname')}
          onChange={(event) => setNewClassName(event.target.value)}
        />
      </div>

      <div className='divNewClassStudents'>
        <NewStudents
          students={newStudentList}
          setStudents={setNewStudentList}
        />
      </div>

      <button
        onClick={handleCreateClass}
        disabled={newStudentList.length < 2 || newClassName === '' || newSchoolName === ''}
        className={`btnCreateClass 
            ${(newStudentList.length < 2 || newClassName === '' || newSchoolName === '') && 'buttonDisabled'}
            `}
      >
        <FontAwesomeIcon className='iconFruitity' icon={faSave} />
      </button>
    </div>
  );
}

export default NewClass;

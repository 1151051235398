import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import './LoginPin.css';
import fruitityIcon from '../assets/fruitity-icon.png';
import { sendPinToUser } from '../utils/APIUtils.js';
import { getLanguages } from '../language/i18n.js';
import Selector from '../components/Selector.js';

/**
 * Component for login with pin.
 * @param {Object} props
 * @param {boolean} props.loading - Is the login with pin loading?
 * @param {function} props.setLoading - Function to set loading to true or false.
 * @param {function} props.setNotification - Function to set a notification.
 * @param {function} props.setClassesData - Function to set classes data.
 * @param {string} props.selectedLanguage - The selected language.
 * @param {function} props.handleLanguageChange - Function to handle language change.
 */
function LoginPin({ loading, setLoading, setNotification, setClassesData, selectedLanguage, handleLanguageChange, fruitImages }) {
    const [email, setEmail] = useState('');
    const [pin, setPin] = useState('');
    const [loginError, setLoginError] = useState(null);

    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loginWithPin } = useAuth();

    /**
     * Sends the pin to the user.
     * @async
     */
    const sendPin = async () => {
        setLoading(true);
        const response = await sendPinToUser(email);
        if (response.ok) {
            setNotification({ msg: t('pin-sended-properly'), type: 'OK' });
        } else {
            setLoginError(t(response.error));
        }
        setLoading(false);
    };

    /**
     * Logs in with pin.
     * @async
     */
    const handleLoginPin = async () => {
        setLoading(true);
        const response = await loginWithPin(email, pin);
        if (response.ok) {
            setClassesData([]);
            const lang = response.data.language || 'es';
            handleLanguageChange(lang);
            navigate('/classes');
        } else {
            setLoginError(t(response.error));
        }
        setLoading(false);
    };

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        handleLanguageChange(savedLanguage ? JSON.parse(savedLanguage) : 'es');

        // eslint-disable-next-line
    }, []);

    const languageOptions = getLanguages();

    return (
        <div className='loginPinDivOutside'>
            <div className='loginPinDivBackground'>
                <div className='loginPinDivBackgroundOutside'>
                    {fruitImages.map(({ id, img, top, left }) => (
                        <img alt="fruit position" key={id} src={img} style={{ top, left }} width="100px" height="100px" />
                    ))}
                </div>
            </div>
            <div className='loginPinDivInside'>
                <div className='loginPinDivImg'>
                    <img alt="fruitity icon" className="loginPinImg" src={fruitityIcon} />
                    <h1 className='menuTitle'>Fruitity</h1>
                </div>
                <div className='loginPinDivForm'>
                    <h1 className='loginPinTitle'>{t('login-pin')}</h1>
                    <div className="loginPinForm">
                        <div className="loginPinInput">
                            <input type="text" id="email" value={email} placeholder={t('email')} onChange={e => setEmail(e.target.value)} />
                            <label htmlFor="email">{t('email')}</label>
                        </div>
                        <div className="loginPinInput">
                            <input type="number" id="pin" value={pin} placeholder={t('pin')} onChange={e => setPin(e.target.value)} />
                            <label htmlFor="pin">{t('pin')}</label>
                        </div>
                        <div className='loginPinInput'>
                            <label htmlFor="language">{t('select_language')}:</label>
                            <Selector options={languageOptions} selectedOption={selectedLanguage} onOptionChange={handleLanguageChange} />
                        </div>
                        <button onClick={sendPin}>{t('send-pin')}</button>
                        <button onClick={handleLoginPin}>{t('login-pin')}</button>
                        <Link to="/login">
                            <p className='loginUserPassword'>{t('login-email-password')}</p>
                        </Link>
                    </div>
                    {loginError && <p className="loginPinError">{loginError}</p>}
                </div>
            </div>
        </div>
    );
}

export default LoginPin;
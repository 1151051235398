import React from 'react';
import './Message.css';

/**
* Message component displays a notification message with styles based on the notification type.
* 
* @param {Object} props - Component properties
* @param {Object} props.notification - Notification object containing message and type
* @param {function} props.setNotification - Function to clear the notification
* @returns {React.ReactElement} The Message component
*/
function Message({ notification, setNotification }) {

    return (
        <div 
            className={`divMessage 
            ${((!notification || !notification.type) && 'divMessageOK') ||
                (notification.type === 'WARN' && 'divMessageWarning') ||
                (notification.type === 'ERR' && 'divMessageError') ||
                (notification.type === 'OK' && 'divMessageOK')}
            `}>
            <p>{notification ? notification.msg : ''}</p>
            <button onClick={() => setNotification(null)}>X</button>
        </div>
    );
};

export default Message;

import React from 'react';
import { useTranslation } from 'react-i18next';
import './NewStudents.css';

/**
 * NewStudents component
 * 
 * This component renders a list of input fields for entering student names
 * for a new class.
 * 
 * The component accepts a students state and an onChange callback
 * as props. The callback is used to update the students state.
 * 
 * The component also uses the onKeyDown event to move focus to the
 * next input field when the user presses enter.
 * 
 * @param {Object} props - Component props
 * @param {Array} props.students - List of student names
 * @param {Function} props.setStudents - Callback to update students state
 */
function NewStudents({ students, setStudents }) {
  const { t } = useTranslation();

  /**
   * Handle key press event on input field
   * 
   * If the user presses enter, move focus to the next input field
   * 
   * @param {Event} event - Event object
   * @param {Number} index - Index of the input field
   */
  const handleKeyDown = (event, index) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      
      const nextElement = document.getElementById(`studentInput${index + 1}`);
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  /**
   * Handle input change event
   * 
   * Update the students state with the new value
   * 
   * @param {Event} event - Event object
   * @param {Number} index - Index of the input field
   */
  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const updatedList = [...students];
    updatedList[index][name] = value;

    if (students[index].name && students[students.length - 1].name) {
      updatedList.push({ name: '' });
    }

    setStudents(updatedList);
  };

  return (
    <div className="divNewClassStudents">
      <h3>{t('new-class-create-students')}</h3>
      {students.map((student, index) => (
        <div className="divNewClassStudent" key={index}>
          <label>{`${t('student')} ${index + 1}:`}</label>
          <input
            type="text"
            name="name"
            id={`studentInput${index}`}
            placeholder={t('name')}
            value={student.name}
            onChange={(event) => handleInputChange(event, index)}
            onKeyDown={(event) => handleKeyDown(event, index)}
          />
        </div>
      ))}
    </div>
  );
}

export default NewStudents;

import React, { useState } from 'react';
import './Selector.css';

/**
 * A component that renders a dropdown selector
 * @prop {array} options the options to be displayed in the dropdown
 * @prop {string} selectedOption the currently selected option
 * @prop {function} onOptionChange the function to be called when an option is changed
 */
function Selector({ options = [], selectedOption = '', onOptionChange }) {
  /**
   * State to keep track of whether the dropdown is open or not
   */
  const [isOpen, setIsOpen] = useState(false);

  /**
   * A function to get the label of the selected option
   * @returns {string} the label of the selected option
   */
  const getSelectedOptionLabel = () => {
    const selected = options.find(option => option.value === selectedOption);
    return selected ? selected.label : '';
  };

  /**
   * A function to be called when an option is changed
   * @param {string} newOptionValue the value of the new option
   */
  const handleOptionChange = (newOptionValue) => {
    onOptionChange(newOptionValue);
    setIsOpen(false);
  };

  /**
   * A function to toggle the state of the dropdown
   */
  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState);
  };

  return (
    <div className='divSelector'>
      <div className="divDropdown">
        <div className="divDropdown-header" onClick={toggleDropdown}>
          {getSelectedOptionLabel()}
          <span className={`arrow ${isOpen ? "open" : ""}`}>▼</span>
        </div>
        {isOpen && (
          <ul className="divDropdown-menu">
            {options.map(option => (
              <li key={option.value} onClick={() => handleOptionChange(option.value)}>
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Selector;

import React, { useState, useEffect } from 'react';

import Podium from '../../components/Podium.js';
import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';

import './StatsView.css';

import { getFruitNameById } from '../../utils/FruitImgUtil.js';


/**
 * This component displays the statistics of a class.
 * It receives the date of the current week and the class object.
 * It calculates the podiums for the week, month and total and displays them in three Podium components.
 * It uses the getWeekStart and getWeekEnd functions to calculate the start and end dates of the week.
 * It uses the getMonthStart and getMonthEnd functions to calculate the start and end dates of the month.
 * It uses the calculatePodiums function to calculate the podiums.
 * It uses the useEffect hook to recalculate the podiums when the date or the class changes.
 * It returns a div with the class name 'divStats' that contains the three Podium components and the titles.
 *
 * @param {Object} props The component props.
 * @param {Date} props.date The date of the current week.
 * @param {Object} props.theClass The class object.
 */
function StatsView({ date, theClass }) {
  const { t } = useTranslation();

  /**
   * The state of the component.
   * It contains the podiums for the week, month and total.
   */
  const [studentWeekPodium, setStudentWeekPodium] = useState([]);
  const [studentMonthPodium, setStudentMonthPodium] = useState([]);
  const [studentTotalPodium, setStudentTotalPodium] = useState([]);

  const [fruitWeekPodium, setFruitWeekPodium] = useState([]);
  const [fruitMonthPodium, setFruitMonthPodium] = useState([]);
  const [fruitTotalPodium, setFruitTotalPodium] = useState([]);

  /**
   * Calculates the start of the week.
   * It takes the date of the current week and returns the start date of the week.
   * @param {Date} date The date of the current week.
   * @return {Date} The start date of the week.
   */
  const getWeekStart = (date) => {
    const day = date.getDay();
    const mondayOffset = day === 0 ? 6 : day - 1;
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() - mondayOffset);
  };

  /**
   * Calculates the end of the week.
   * It takes the date of the current week and returns the end date of the week.
   * @param {Date} date The date of the current week.
   * @return {Date} The end date of the week.
   */
  const getWeekEnd = (date) => {
    const startDate = getWeekStart(date);
    return new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6);
  };

  /**
   * Calculates the start of the month.
   * It takes the date of the current week and returns the start date of the month.
   * @param {Date} date The date of the current week.
   * @return {Date} The start date of the month.
   */
  const getMonthStart = (date) => new Date(date.getFullYear(), date.getMonth(), 1);

  /**
   * Calculates the end of the month.
   * It takes the date of the current week and returns the end date of the month.
   * @param {Date} date The date of the current week.
   * @return {Date} The end date of the month.
   */
  const getMonthEnd = (date) => {
    const month = date.getMonth();
    const year = date.getFullYear();
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextYear = month === 11 ? year + 1 : year;
    return new Date(nextYear, nextMonth, 0);
  };

  /**
   * Calculates the podiums.
   * It calculates the podiums for the week, month and total.
   * It sets the state of the component with the calculated podiums.
   */
  const calculatePodiums = () => {
    const startWeekDate = getWeekStart(date);
    const endWeekDate = getWeekEnd(date);
    const startMonthDate = getMonthStart(date);
    const endMonthDate = getMonthEnd(date);

    let allMeals = [];

    const studentWeekResults = [];
    const studentMonthResults = [];
    const studentTotalResults = [];

    theClass.students.forEach((student) => {
      allMeals = allMeals.concat(student.meals);

      const pointsInWeek = student.meals.reduce((sum, meal) => {
        const mealDate = new Date(meal.date);
        return mealDate >= startWeekDate && mealDate <= endWeekDate ? sum + meal.points : sum;
      }, 0);

      const pointsInMonth = student.meals.reduce((sum, meal) => {
        const mealDate = new Date(meal.date);
        return mealDate >= startMonthDate && mealDate <= endMonthDate ? sum + meal.points : sum;
      }, 0);

      const totalPoints = student.meals.reduce((sum, meal) => sum + meal.points, 0);

      studentWeekResults.push({ _id: student._id, name: student.name, total: pointsInWeek });
      studentMonthResults.push({ _id: student._id, name: student.name, total: pointsInMonth });
      studentTotalResults.push({ _id: student._id, name: student.name, total: totalPoints });
    });

    const fruitWeekResults = [];
    const fruitMonthResults = [];
    const fruitTotalResults = [];

    allMeals.forEach((meal) => {
      const mealDate = new Date(meal.date);

      const updateFruitResults = (results, condition) => {
        if (condition) {
          let fruit = results.find((fr) => fr.fruit === meal.fruit);
          if (!fruit) {
            fruit = {
              fruit: meal.fruit,
              name: t(getFruitNameById(meal.fruit)),
              total: 0,
            };
            results.push(fruit);
          }
          fruit.total += meal.points;
        }
      };

      updateFruitResults(fruitWeekResults, mealDate >= startWeekDate && mealDate <= endWeekDate);
      updateFruitResults(fruitMonthResults, mealDate >= startMonthDate && mealDate <= endMonthDate);
      updateFruitResults(fruitTotalResults, true);
    });

    const sortDescending = (a, b) => b.total - a.total;

    studentWeekResults.sort(sortDescending);
    studentMonthResults.sort(sortDescending);
    studentTotalResults.sort(sortDescending);
    fruitWeekResults.sort(sortDescending);
    fruitMonthResults.sort(sortDescending);
    fruitTotalResults.sort(sortDescending);

    setStudentWeekPodium(studentWeekResults);
    setStudentMonthPodium(studentMonthResults);
    setStudentTotalPodium(studentTotalResults);
    setFruitWeekPodium(fruitWeekResults);
    setFruitMonthPodium(fruitMonthResults);
    setFruitTotalPodium(fruitTotalResults);
  };

  /**
   * The effect hook.
   * It recalculates the podiums when the date or the class changes.
   */
  useEffect(() => {
    calculatePodiums();
    // eslint-disable-next-line
  }, [date, theClass]);

  /**
   * The component.
   * It returns a div with the class name 'divStats' that contains the three Podium components and the titles.
   */
  return (
    <div className='divStats'>
      <h1>{t('stats-student')}</h1>
      <div className='divStatsStudents'>
        <Podium title={t('stats-week')} participants={studentWeekPodium} />
        <Podium title={t('stats-month')} participants={studentMonthPodium} />
        <Podium title={t('stats-total')} participants={studentTotalPodium} />
      </div>
      <h1>{t('stats-fruits')}</h1>
      <div className='divStatsFruits'>
        <Podium title={t('stats-week')} participants={fruitWeekPodium} />
        <Podium title={t('stats-month')} participants={fruitMonthPodium} />
        <Podium title={t('stats-total')} participants={fruitTotalPodium} />
      </div>
    </div>
  );
}

export default StatsView;

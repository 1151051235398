import React, { useEffect, useState } from 'react';
import './ClassList.css';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { faAdd, faFolder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * ClassList component
 * 
 * This component is used to show a list of all classes to which the user belongs.
 * It also allows the user to create a new class by clicking on the add button.
 * 
 * @param {Object} user user data
 * @param {Array} classesData list of classes to which the user belongs
 * @param {Function} setClassesData function to set the list of classes
 * @param {Array} schoolsData list of schools
 * @param {Object} notification notification data
 * @param {Function} setNotification function to set the notification data
 * @param {Boolean} loading boolean to indicate if the component is loading
 * @param {Function} setLoading function to set the loading state
 * @param {Object} selectedClass the currently selected class
 * @param {Function} setSelectedClass function to set the selected class
 * @param {Function} handleLogout function to handle the user logout
 */
function ClassList({ user, classesData, setClassesData, schoolsData, notification, setNotification, loading, setLoading, selectedClass, setSelectedClass, handleLogout }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showArchived, setShowArchived] = useState(false);

  /**
   * Toggles the visibility of archived classes.
   * If showArchived is true, archived classes will be shown.
   * If showArchived is false, archived classes will be hidden.
   */
  const toggleShowArchived = () => {
    setShowArchived(prevState => !prevState);
  };

  /**
   * Handle the user click on the new class button
   */
  const handleNewClassNavigation = () => {
    navigate('/newclass');
  }

  /**
   * Handle the user click on a class item
   * @param {Object} classItem the class item that was clicked
   */
  const handleClassSelection = (classItem) => {
    setSelectedClass(classItem);
    navigate(`/classes/${classItem._id}`);
  }

  /**
   * Use effect to set the selected class to null when the component is mounted
   */
  useEffect(() => {
    setSelectedClass(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedClass]);

  return (
    <div>
      <div className='divClassListFirstRow'>
        <h1>{t('classlist')}</h1>
        <FontAwesomeIcon onClick={toggleShowArchived} className={`iconFruitity iconArchive ${showArchived ? ' iconArchiveSelected' : ''}`} icon={faFolder} />
      </div>

      {(!classesData || classesData.length === 0) && (
        <h2>{t('no-classes')}</h2>
      )}

      <div className="divClassList">
        {classesData && classesData
          .sort((a, b) => a.name.localeCompare(b.name))
          .filter((a) => !a.archived)
          .map((classItem) => (
            <div id={`class${classItem._id}`} className="divClassItem" key={classItem._id} onClick={() => handleClassSelection(classItem)}>
              <h2 className="className">{classItem.name}</h2>
              <p className="schoolName">{classItem.school_name}</p>
              {classItem.user_id !== user._id && (
                <p className='invited'>{t('invited')}</p>
              )}
              {
                classItem.archived && (
                  <FontAwesomeIcon className={`iconFruitity iconArchived`} icon={faFolder} />
                )
              }
            </div>
          ))}
      </div>

      {showArchived &&
        <>
          <h1>{t('archived-classes')}</h1>
          <div className="divClassList">
            {classesData && classesData
              .sort((a, b) => a.name.localeCompare(b.name))
              .filter((a) => a.archived)
              .map((classItem) => (
                <div id={`class${classItem._id}`} className="divClassItem" key={classItem._id} onClick={() => handleClassSelection(classItem)}>
                  <h2 className="className">{classItem.name}</h2>
                  <p className="schoolName">{classItem.school_name}</p>
                  {classItem.user_id !== user._id && (
                    <p className='invited'>{t('invited')}</p>
                  )}
                  {
                    classItem.archived && (
                      <FontAwesomeIcon className={`iconFruitity iconArchived`} icon={faFolder} />
                    )
                  }
                </div>
              ))}
          </div>
          {(!classesData || classesData.length === 0 || classesData.filter((a) => a.archived).length === 0) && (
            <h2>{t('no-archived-classes')}</h2>
          )}
        </>
      }

      <button className='btnNewClassText' onClick={handleNewClassNavigation}>
        <FontAwesomeIcon className='iconFruitity' icon={faAdd} />
      </button>
    </div>
  );
}

export default ClassList;

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../../../language/i18n.js';

import './AdminNews.css';
import handleResponseError, { adminAddNew } from '../../../utils/APIUtils.js';

function AdminNews({ user, notification, setNotification, loading, setLoading, handleLogout, newsList, setNewsList }) {

  const { t } = useTranslation();

  const [newsData, setNewsData] = useState({
    title: '',
    titleES: '',
    descES: '',
    titleCA: '',
    descCA: '',
    titleEN: '',
    descEN: '',
    titleGL: '',
    descGL: '',
    titleEU: '',
    descEU: ''
  });

  const handleChange = (event, field) => {
    setNewsData({ ...newsData, [field]: event.target.value });
  };



  const add_new_new = async () => {
    setLoading(true);
    const {
      title, titleES, descES, titleCA, descCA, titleEN, descEN,
      titleGL, descGL, titleEU, descEU
    } = newsData;
    const theData = await adminAddNew(
      user.token,
      title, titleCA, descCA, titleES, descES, titleEN, descEN,
      titleGL, descGL, titleEU, descEU
    );

    if (theData && theData.ok) {
      let theNewsList = [...newsList];
      theNewsList.push(theData.data);
      setNewsList(theNewsList);
    } else {
      handleResponseError(theData, t, handleLogout, setNotification);
    }
    setLoading(false);
  }


  return (
    <div className='divAdminNews'>
      <div className='divAdminAddNew'>
        {['', 'ES', 'CA', 'EN', 'GL', 'EU'].map(lang => (
          <div key={lang}>
            <label>{t(`title${lang ? `_${lang.toLowerCase()}` : ''}`)}:</label>
            <input
              type="text"
              value={newsData[`title${lang}`]}
              onChange={(e) => handleChange(e, `title${lang}`)}
            />
            <label>{t(`desc${lang ? `_${lang.toLowerCase()}` : ''}`)}:</label>
            <textarea
              value={newsData[`desc${lang}`]}
              onChange={(e) => handleChange(e, `desc${lang}`)}
            />
          </div>
        ))}
        <button onClick={() => add_new_new()}>{t('add_new_new')}</button>

      </div>
      <div className='divAdminNewsList'>
        {newsList && newsList.map((n) => (
          <div className='divAdminNew' key={n._id}>
            <h1>{n['title']}</h1>
            <p>{n['creationDate']}</p>
            {['ES', 'CA', 'EN', 'GL', 'EU'].map(lang => (
              <div key={lang}>
                <p>{n[`title${lang}`]}</p>
                <p>{n[`description${lang}`]}</p>
              </div>
            ))}
          </div>
        ))
        }
      </div>
    </div>

  );
};

export default AdminNews;

import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import '../language/i18n.js';

import './User.css';
import handleResponseError, { editUser, removeUser } from '../utils/APIUtils.js';
import { useAuth } from '../context/AuthContext.js';
import { faCancel, faEdit, faEye, faEyeSlash, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLanguageNameFromLang, getLanguages } from '../language/i18n.js';
import Selector from '../components/Selector.js';
import Modal from '../components/Modal.js';
function User({ user, notification, setNotification, loading, setLoading, handleLogout, handleLanguageChange }) {
  const { t } = useTranslation();

  const { editUserAuth } = useAuth();

  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(user?.name || '');
  const [surname, setSurname] = useState(user?.surname || '');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [language, setLanguage] = useState(user?.language || 'es');

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [errors, setErrors] = useState({
    name: false,
    surname: false,
    password: false,
    confirmPassword: false
  });

  const handleClickEye = () => {
    setShowPassword(!showPassword);
  };
  const handleClickEyeConfirm = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClickEdit = () => {
    setName(user.name);
    setSurname(user.surname);
    setPassword('');
    setConfirmPassword('');
    setLanguage(user.language);
    setEditing(true);
  };

  const checkCorrectData = () => {
    const newErrors = { ...errors };
    let correctData = true;

    if (name.length > 255) {
      newErrors.name = true;
      correctData = false;
    }

    if (surname.length > 255) {
      newErrors.surname = true;
      correctData = false;
    }

    if (password !== '') {
      const regexPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
      if (password.length < 8 || !regexPwd.test(password)) {
        newErrors.password = true;
        correctData = false;
      }

      if (password !== confirmPassword) {
        newErrors.confirmPassword = true;
        correctData = false;
      }
    }

    if (!correctData) {
      setErrors(newErrors);
    } else {
      setErrors({
        name: false,
        surname: false,
        password: false,
        confirmPassword: false
      });
    }
    return correctData;
  };

  const handleClickSave = async () => {
    setLoading(true);

    let correctData = checkCorrectData();

    if (correctData) {
      const response = await editUser(user.token, name, surname, password !== '', password, language);

      if (response?.ok) {
        editUserAuth(response.data);
        handleLanguageChange(response.data.language || 'es');
      } else {
        handleResponseError(response, t, handleLogout, setNotification);
      }
      setEditing(false);
    }

    setLoading(false);
  };

  const optionsLanguage = getLanguages();

  const handleClickDelete = () => {
    setShowModalDeleteUser(true);
  };

  const deleteUser = async (params) => {
    setLoading(true);

    let capchaEmail = params ? params.capchaEmail : '';
    let capchaRemove = params ? params.capchaRemove : '';
    let continueDeleting = true;
    if (capchaEmail !== user.email) {
      setNotification({ msg: t('msg-cant-remove-user-capchaemail'), type: 'ERR' });
      continueDeleting = false;
    }

    if (capchaRemove !== t('remove')) {
      setNotification({ msg: t('msg-cant-remove-user-capcharemove'), type: 'ERR' });
      continueDeleting = false;
    }

    if (continueDeleting) {
      const theData = await removeUser(user.token, user._id);

      if (theData && theData.ok) {
        handleLogout();
        setNotification({ msg: t('user-deleted'), type: 'OK' });
      } else {
        handleResponseError(theData, t, handleLogout, setNotification);
      }
    }

    setLoading(false);
    setShowModalDeleteUser(false);
  };

  const cancelDeleteUser = () => {
    setShowModalDeleteUser(false);
  };

  const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);

  return (
    <div>
      <div className='divUserModal'>
        {showModalDeleteUser && <Modal
          notification={t('modal-sure-remove-user')}
          confirmText={t('modal-btn-delete-ok')}
          cancelText={t('modal-btn-delete-cancel')}
          onConfirm={deleteUser}
          onCancel={cancelDeleteUser}
          confirmIcon={faTrash}
          cancelIcon={faCancel}
          fields={[
            { name: 'capchaEmail', type: 'text', placeholder: user.email, mandatory: true },
            { name: 'capchaRemove', type: 'text', placeholder: t('remove'), mandatory: true },
          ]}
        />}
      </div>
      <h1>{t('user')}</h1>
      <div className='divUserFields'>
        <div className='divUserField divUserName'>
          <p>{t('name')}</p>
          {!editing && <h3>{user?.name || '-'}</h3>}
          {editing &&
            <input name='name' type='text' value={name} onChange={(e) => setName(e.target.value)} />
          }
        </div>
        {errors.name && <p className='updateUserError'>{t('wrongSignupName')}</p>}

        <div className='divUserField divUserSurname'>
          <p>{t('surname')}</p>
          {!editing && <h3>{user?.surname || '-'}</h3>}
          {editing &&
            <input name='surname' type='text' value={surname} onChange={(e) => setSurname(e.target.value)} />
          }
        </div>
        {errors.surname && <p className='updateUserError'>{t('wrongSignupSurname')}</p>}

        <div className='divUserField divUserEmail'>
          <p>{t('email')}</p>
          <h3>{user?.email || '-'}</h3>
        </div>

        {!editing &&
          <div>
            <div className='divUserField divUserPassword'>
              <p>{t('password')}</p>
              <h3>****</h3>
            </div>
          </div>
        }

        {editing &&
          <>
            <div className='divUserField divUserPassword'>
              <p>{t('password')}</p>
              <input name='password' type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} />
              <FontAwesomeIcon className='loginEyeIcon' icon={showPassword ? faEye : faEyeSlash} onClick={handleClickEye} />
            </div>
            {errors.password && <p className='updateUserError'>{t('wrongSignupPassword')}</p>}

            <div className='divUserField divUserRepassword'>
              <p>{t('repassword')}</p>
              <input name='repassword' type={showConfirmPassword ? 'text' : 'password'} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              <FontAwesomeIcon className='loginEyeIcon' icon={showConfirmPassword ? faEye : faEyeSlash} onClick={handleClickEyeConfirm} />
            </div>
            {errors.confirmPassword && <p className='updateUserError'>{t('wrongSignupRepassword')}</p>}
          </>
        }
        {
          !editing &&
          <div>
            <div className='divUserField'>
              <p>{t('language')}</p>
              <h3>{getLanguageNameFromLang(language)}</h3>
            </div>
          </div>
        }
        {editing &&
          <div className='divUserField divUserLanguage'>
            <p>{t('select_language')}:</p>
            <Selector
              options={optionsLanguage}
              selectedOption={language}
              onOptionChange={setLanguage} />
          </div>
        }

        <div className='divUserBtns'>
          {!editing && <button onClick={handleClickEdit}><FontAwesomeIcon className='iconFruitity' icon={faEdit} /></button>}
          {editing && <button onClick={handleClickSave}><FontAwesomeIcon className='iconFruitity' icon={faSave} /></button>}
          {!editing && <button onClick={handleClickDelete}><FontAwesomeIcon className='iconFruitity' icon={faTrash} /></button>}
        </div>

      </div>
    </div>
  );
};

export default User;

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';

import Login from './Login';
import Signup from './Signup';
import ClassList from './classes/ClassList';
import ClassView from './classes/ClassView';
import User from './User';
import Awards from './Awards';

import { getLanguage } from '../language/i18n.js';
import Layout from '../components/Layout';

import { useAuth } from '../context/AuthContext';
import '../App.css';
import '@fontsource/montserrat';
import handleResponseError, { getDataFromUser, getSchoolsData, getAwards, getInfoUser } from '../utils/APIUtils.js';
import NewClass from './classes/NewClass.js';
import LoginPin from './LoginPin.js';
import Admin from './admin/Admin.js';
import Games from './games/Games.js';
import News from './News.js';
import { getAllFruitImage } from '../utils/FruitImgUtil.js';
import BuyMeACoffee from './BuyMeACoffee.js';

/**
 * The main component of the application. It handles the routes and the data fetching
 * @returns {JSX.Element} The main component
 */
function Main() {
  const [selectedClass, setSelectedClass] = useState(null);
  const [classData, setClassData] = useState(null);
  const [schoolData, setSchoolData] = useState([]);
  const [awardData, setAwardData] = useState([]);
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(getLanguage() || 'es');
  const [fruitImages, setFruitImages] = useState([]);

  const { user, logoutCurrentUser, editUserAuth } = useAuth();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => setNotification(null), 2000);
    return () => clearTimeout(timer);
  }, [notification]);

   useEffect(() => {

        if(fruitImages.length === 0) {
            const fruits = getAllFruitImage();
            const positions = fruits.map((fruit) => ({
                id: fruit._id,
                img: fruit.img,
                top: `${Math.floor(Math.random() * (window.innerHeight - 100))}px`,
                left: `${Math.floor(Math.random() * (window.innerWidth - 100))}px`
            }));
            setFruitImages(positions);
        }

        
        // eslint-disable-next-line
    }, []);

  /**
  * Handles language change and updates local storage.
  * @param {string} language - The language to switch to.
  */
  const handleLanguageChange = (language) => {
      i18n.changeLanguage(language);
      localStorage.setItem('lang', JSON.stringify(language));
      setSelectedLanguage(language);
  };

  /**
   * Handles the logout
   */
  const handleLogout = () => {
    logoutCurrentUser();
  };

  /**
   * Fetches the user data and updates the application state accordingly.
   * - Sets the user information and changes the language.
   * - Loads additional data if the user is enabled.
   */
  const fetchUserData = async () => {
    setIsLoading(true);
    
    // Fetch user information using the provided token
    const data = await getInfoUser(user.token);

    if (data.ok) {
      // Update user object with fetched data
      Object.assign(user, {
        name: data.name,
        surname: data.surname,
        enabled: data.enabled,
        language: data.language,
        news: data.news,
      });
      
      // Update the authentication state and change application language
      editUserAuth(user);
      handleLanguageChange(data.language);

      // Load additional data if the user is enabled
      if (user.enabled) {
        loadData();
        loadSchoolData();
        loadAwardData();
      }
    } else {
      // Handle errors in fetching user data
      handleResponseError(data, t, handleLogout, setNotification);
    }

    setIsLoading(false);
  };

  /**
   * Fetches the class data
   */
  const loadData = async () => {
    setIsLoading(true);
    const data = await getDataFromUser(user.token);

    if (data.ok) {
      setClassData(data.data);
    } else {
      handleResponseError(data, t, handleLogout, setNotification);
    }
    setIsLoading(false);
  };

  /**
   * Fetches the school data from the server and updates the application state.
   * Sets loading state while the request is in progress.
   */
  const loadSchoolData = async () => {
    // Set loading state to true
    setIsLoading(true);

    // Fetch school data using the user's token
    const data = await getSchoolsData(user.token);

    // If data is successfully retrieved, update the school data state
    if (data.ok) {
      setSchoolData(data.data);
    } else {
      // Handle any errors during the data fetch
      handleResponseError(data, t, handleLogout, setNotification);
    }

    // Reset loading state to false
    setIsLoading(false);
  };

  /**
   * Fetches the award data from the server and updates the application state.
   * Sets loading state while the request is in progress.
   */
  const loadAwardData = async () => {
    // Set loading state to true
    setIsLoading(true);

    // Fetch award data using the user's token
    const data = await getAwards(user.token);

    // If data is successfully retrieved, update the award data state
    if (data.ok) {
      setAwardData(data.data);
    } else {
      // Handle any errors during the data fetch
      handleResponseError(data, t, handleLogout, setNotification);
    }

    // Reset loading state to false
    setIsLoading(false);
  };

  useEffect(() => {
    if (user) fetchUserData();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const lang = getLanguage();
    if (lang) handleLanguageChange(lang);
    // eslint-disable-next-line
  }, [i18n]);

  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}> 
      <Routes>
        {user ? (
          <Route
            element={
              <Layout
                user={user}
                loading={isLoading}
                setLoading={setIsLoading}
                notification={notification}
                setNotification={setNotification}
                menu
                classesData={classData}
                selectedClass={selectedClass}
                setSelectedClass={setSelectedClass}
                handleLogout={handleLogout}
              />
            }
          >
            <Route
              path="/classes"
              element={
                <ClassList
                  classesData={classData}
                  setClassesData={setClassData}
                  user={user}
                  schoolsData={schoolData}
                  notification={notification}
                  setNotification={setNotification}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  selectedClass={selectedClass}
                  setSelectedClass={setSelectedClass}
                  handleLogout={handleLogout}
                />
              }
            />
            <Route
              path="/classes/:id"
              element={
                <ClassView
                  awards={awardData}
                  classesData={classData}
                  user={user}
                  setClassesData={setClassData}
                  notification={notification}
                  setNotification={setNotification}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  handleLogout={handleLogout}
                  setSelectedClass={setSelectedClass}
                />
              }
            />
            <Route
              path="/newclass"
              element={
                <NewClass
                  user={user}
                  notification={notification}
                  setNotification={setNotification}
                  schools={schoolData}
                  setSchools={setSchoolData}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  classesData={classData}
                  setClassesData={setClassData}
                  handleLogout={handleLogout}
                />
              }
            />
            <Route
              path="/user"
              element={
                <User
                  user={user}
                  notification={notification}
                  setNotification={setNotification}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  handleLogout={handleLogout}
                  handleLanguageChange={handleLanguageChange}
                />
              }
            />
            <Route
              path="/awards"
              element={
                <Awards
                  user={user}
                  notification={notification}
                  setNotification={setNotification}
                  awards={awardData}
                  setAwardsData={setAwardData}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  handleLogout={handleLogout}
                />
              }
            />
            <Route
              path="/games"
              element={
                <Games
                  user={user}
                  notification={notification}
                  setNotification={setNotification}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  handleLogout={handleLogout}
                />
              }
            />
            <Route
              path="/news"
              element={
                <News
                  user={user}
                  notification={notification}
                  setNotification={setNotification}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  handleLogout={handleLogout}
                />
              }
            />
            <Route
              path="/buymeacoffee"
              element={
                <BuyMeACoffee
                  user={user}
                  notification={notification}
                  setNotification={setNotification}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  handleLogout={handleLogout}
                />
              }
            />
            <Route
              path="/admin"
              element={
                <Admin
                  user={user}
                  notification={notification}
                  setNotification={setNotification}
                  awards={awardData}
                  setAwardsData={setAwardData}
                  loading={isLoading}
                  setLoading={setIsLoading}
                  handleLogout={handleLogout}
                />
              }
            />
            <Route path="*" element={<Navigate to="/classes" />} />
          </Route>
        ) : (
          <Route
            element={
              <Layout
                loading={isLoading}
                notification={notification}
                setNotification={setNotification}
              />
            }
          >
            <Route
              path="/login"
              element={
                <Login
                  loading={isLoading}
                  setLoading={setIsLoading}
                  setNotification={setNotification}
                  setClassesData={setClassData}
                  selectedLanguage={selectedLanguage}
                  handleLanguageChange={handleLanguageChange}
                  fruitImages={fruitImages}
                />
              }
            />
            <Route
              path="/login/:msg"
              element={
                <Login
                  loading={isLoading}
                  setLoading={setIsLoading}
                  setNotification={setNotification}
                  setClassesData={setClassData}
                  selectedLanguage={selectedLanguage}
                  handleLanguageChange={handleLanguageChange}
                  fruitImages={fruitImages}
                />
              }
            />
            <Route
              path="/loginpin"
              element={
                <LoginPin
                  loading={isLoading}
                  setLoading={setIsLoading}
                  setNotification={setNotification}
                  setClassesData={setClassData}
                  selectedLanguage={selectedLanguage}
                  handleLanguageChange={handleLanguageChange}
                  fruitImages={fruitImages}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <Signup
                  loading={isLoading}
                  setLoading={setIsLoading}
                  notification={notification}
                  setNotification={setNotification}
                  setClassesData={setClassData}
                  selectedLanguage={selectedLanguage}
                  handleLanguageChange={handleLanguageChange}
                  fruitImages={fruitImages}
                />
              }
            />
            <Route path="*" element={<Navigate to="/login" />} />
          </Route>
        )}
      </Routes>
    </Router>
  );
}

export default Main;


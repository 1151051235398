import React, { useEffect, useRef, useState } from 'react';
import './Layout.css';
import { Outlet } from 'react-router-dom';
import Message from './Message';
import Loading from './Loading';
import Menu from './Menu';
import { useTranslation } from 'react-i18next';
import { sendActivationEmail } from '../utils/APIUtils';
import Cookies from './Cookies';

/**
 * Layout component which acts as a wrapper for the application layout.
 * It manages the display of loading, menu, cookies, notifications, and user activation functionalities.
 * 
 * @param {Object} props - Component properties
 * @param {Object} props.user - Current user object
 * @param {boolean} props.loading - Loading state
 * @param {function} props.setLoading - Function to set loading state
 * @param {Object} props.notification - Notification object
 * @param {function} props.setNotification - Function to set notification state
 * @param {boolean} props.menu - Menu state
 * @param {Array} props.classesData - Array of classes data
 * @param {Object} props.selectedClass - Currently selected class
 * @param {function} props.setSelectedClass - Function to set selected class
 * @param {function} props.handleLogout - Function to handle user logout
 * @returns {React.ReactElement} The Layout component
 */
const Layout = ({
  user,
  loading,
  setLoading,
  notification,
  setNotification,
  menu,
  classesData,
  selectedClass,
  setSelectedClass,
  handleLogout
}) => {
  const { t } = useTranslation();
  const divVScrollRef = useRef(null);
  const [divVScrollHeight, setDivVScrollHeight] = useState(100);
  const [showCookies, setShowCookies] = useState(true);

  /**
   * Accepts cookies and updates local storage and state accordingly.
   */
  const acceptCookies = () => {
    localStorage.setItem('cookies', true);
    setShowCookies(false);
  };

  /**
   * Sends an activation email to the user.
   * Updates loading and notification states based on the response.
   */
  const sendEnableUserEmail = async () => {
    setLoading(true);
    const response = await sendActivationEmail(user.token);

    if (response.ok) {
      setNotification({ msg: t('email-activation-sended'), type: 'OK' });
    } else {
      setNotification({ msg: t(response.error), type: 'ERR' });
    }

    setLoading(false);
  };

    /**
     * Recalculates the height of the scrollable div based on the window size.
    */
   const recalculateHeightVScroll = () => {
     if (divVScrollRef.current) {
       const windowHeight = window.innerHeight;
       const elementTop = divVScrollRef.current.getBoundingClientRect().top;
       const calculatedHeight = windowHeight - elementTop;
       setDivVScrollHeight(calculatedHeight);
       //console.log(calculatedHeight);
      }
    };

  useEffect(() => {
    /**
     * Checks and sets the cookie consent status from local storage.
     */
    try {
      const cachedCookies = localStorage.getItem('cookies');

      if (cachedCookies !== null) {
        setShowCookies(cachedCookies === 'true' ? false : true);
      } else {
        setShowCookies(true);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  /**
   * Calculates the height of the scrollable div when the component is mounted.
   * Adds an event listener to recalculate the height when the window is resized.
   * Removes the event listener when the component is unmounted.
   */
  useEffect(() => {
    // Calculate the initial height of the scrollable div
    recalculateHeightVScroll();

    // Add an event listener to recalculate the height when the window is resized
    window.addEventListener('resize', recalculateHeightVScroll);

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', recalculateHeightVScroll);
    };
  }, [menu]);

  /**
   * Recalculates the height of the scrollable div when the menu state changes.
   * This is necessary because the menu's height affects the height of the scrollable div.
   */
  useEffect(() => {
    if (menu) {
      // Recalculate the height of the scrollable div
      recalculateHeightVScroll();
    }
  }, [menu]);

  return (
    <div>
      {loading && <Loading />}
      {menu && <Menu user={user} classesData={classesData} selectedClass={selectedClass} setSelectedClass={setSelectedClass} />}
      {showCookies && <Cookies setShowCookies={setShowCookies} acceptCookies={acceptCookies} handleLogout={handleLogout} />}
      {notification && <Message notification={notification} setNotification={setNotification} />}
      {user && !user.enabled && (
        <>
          <h3 className="warningUserNotEnabledYet">{t('user-not-enabled-yet')}</h3>
          <button className="btnSendActivationEmailAgain" onClick={sendEnableUserEmail}>
            {t('send-activation-email')}
          </button>
        </>
      )}
      <div
        ref={divVScrollRef}
        className="divVScroll"
        style={{ height: `${divVScrollHeight}px` }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
import React, { useEffect, useState } from 'react';
import './Podium.css';
import '../language/i18n.js';
import { useTranslation } from 'react-i18next';

//Chart is necessary to Bar works
// eslint-disable-next-line
import Chart from 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

/**
 * A component that displays a podium with the top 3 participants
 * and a bar chart with the points of all participants.
 *
 * @param {string} title The title of the podium.
 * @param {Object[]} participants An array of objects with the following properties:
 *   - name: The name of the participant.
 *   - total: The total points of the participant.
 *   - color: The color of the participant. Optional.
 * @returns {JSX.Element} The Podium component.
 */
function Podium({ title, participants }) {
  const { t } = useTranslation();

  const [chartOptions, setChartOptions] = useState({});
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    // Set the chart options
    setChartOptions({
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
    });
  
    if (participants && participants.length > 0) {
      // Set the chart data
      setChartData({
        labels: participants.map((participant) => participant.name),
        datasets: [
          {
            label: t('podium-total-points'),
            data: participants.map((participant) => participant.total),
            backgroundColor: participants.map((participant) => participant.color || '#e8998d'),
          },
        ],
      });
    } else {
      setChartData({});
    }
  }, [participants, t]);

  return (
    <div className="divPodium">
      <h2>{title}</h2>
      <div className="divPodiumTop3">
        {participants && participants.length >= 1 && (
          <div>
            <FontAwesomeIcon className="podiumPosition" icon={faTrophy} />
            <h3>{participants[0].name}</h3>
          </div>
        )}
        {participants && participants.length >= 2 && (
          <div>
            <FontAwesomeIcon className="podiumPosition" icon={faTrophy} />
            <h3>{participants[1].name}</h3>
          </div>
        )}
        {participants && participants.length >= 3 && (
          <div>
            <FontAwesomeIcon className="podiumPosition" icon={faTrophy} />
            <h3>{participants[2].name}</h3>
          </div>
        )}
      </div>
      <div className="divPodiumBar">
        {chartData && chartData.datasets?.length > 0 && chartData.labels?.length > 0? (
          <Bar data={chartData} options={chartOptions} />
        ) : (
          <p>{t('no-stats-yet')}</p>
        )}
      </div>
    </div>
  );
}

export default Podium;

import React from 'react';
import './Loading.css';
import fruitityIcon from '../assets/fruitity-icon.png';

/*************  ✨ Codeium Command 🌟  *************/
/**
 * The Loading component is used to display a loading animation.
 * It displays a fruitityIcon spinning.
 * @returns The JSX for the Loading component.
 */
function Loading() {
  // The divLoading class is defined in Loading.css
  // It positions the loading animation in the center of the screen
  // and makes it spin.
 
  return (
        <div className='divLoading'>
          {/* The fruitityIcon is a PNG image */}
          <div>
            <img alt="fruitityicon" src={fruitityIcon}/>
          </div>
        </div>
  );
};
/******  46184a92-846a-4f3b-a95b-ef24df370196  *******/

export default Loading;

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { useNavigate, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import './Login.css';
import fruitityIcon from '../assets/fruitity-icon.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import { auth, googleProvider } from '../firebase/firebase';
import { signInWithPopup } from 'firebase/auth';
import Selector from '../components/Selector.js';
import { getLanguages } from '../language/i18n.js';

/**
 * Login component for handling user authentication.
 * Supports email/password login and Google sign-in.
 * Also allows users to select a language preference.
 */
function Login({ loading, setLoading, setNotification, setClassesData, selectedLanguage, handleLanguageChange, fruitImages }) {
    // State variables to track input values and other UI states
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [loginError, setLoginError] = useState(null);
    
    // Hooks for navigation and translation
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { loginWithPassword, loginWithGoogle } = useAuth();
    const { msg } = useParams();

    /**
     * Handles Google sign-in via Firebase authentication.
     */
    const handleGoogleSignIn = async () => {
        try {
            await signInWithPopup(auth, googleProvider);
            const currentUser = auth.currentUser;
            loginWithGoogle(currentUser, selectedLanguage);
        } catch (error) {
            console.error("Google sign-in error:", error);
        }
    };

    /**
     * Handles login with email and password.
     */
    const handlePasswordLogin = async () => {
        setLoading(true);
        const response = await loginWithPassword({ email, password });
        if (response.ok) {
            setClassesData([]); // Clear classes data
            const userLanguage = response.data.language || 'es';
            handleLanguageChange(userLanguage);
            navigate('/classes'); // Redirect to classes page
        } else {
            setLoginError(t(response.error)); // Set login error
        }
        setLoading(false);
    };

    /**
     * Toggles the visibility of the password input.
     */
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    // Effect hook to handle notifications
    useEffect(() => {

        if (msg) {
            setNotification({ msg: t(msg), type: 'ERR' });
        }

        const savedLanguage = localStorage.getItem('language');
        handleLanguageChange(savedLanguage ? JSON.parse(savedLanguage) : 'es');

        // eslint-disable-next-line
    }, [msg, setNotification]);

    // Get available languages
    const languages = getLanguages();

    return (
        <div className='loginDivOutside'>
            <div className='loginDivBackground'>
                <div className='loginDivBackgroundOutside'>
                    {fruitImages.map((fruit) => (
                        <img
                            alt="fruit"
                            width='100px'
                            height='100px'
                            src={fruit.img}
                            key={fruit.id}
                            style={{ top: fruit.top, left: fruit.left }}
                        />
                    ))}
                </div>
            </div>
            <div className='loginDivInside'>
                <div className='loginDivImg'>
                    <img alt="icon" className="loginImg" src={fruitityIcon} />
                    <h1 className='menuTitle'>Fruitity</h1>
                </div>
                <div className='loginDivForm'>
                    <h1 className='loginTitle'>{t('login')}</h1>
                    <div className="loginForm">
                        <div className="loginInput">
                            <input
                                type="email"
                                id="email"
                                value={email}
                                placeholder={t('email')}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <label htmlFor="email">{t('email')}</label>
                        </div>
                        <div className="loginInput">
                            <label htmlFor="password">{t('password')}</label>
                            <div className='loginDivPassword'>
                                <input
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    id="password"
                                    value={password}
                                    placeholder={t('password')}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <FontAwesomeIcon
                                    className="loginEyeIcon"
                                    icon={isPasswordVisible ? faEye : faEyeSlash}
                                    onClick={togglePasswordVisibility}
                                />
                            </div>
                        </div>
                        <div className='loginInput'>
                            <label>{t('select_language')}:</label>
                            <Selector
                                options={languages}
                                selectedOption={selectedLanguage}
                                onOptionChange={handleLanguageChange}
                            />
                        </div>
                        <button onClick={handlePasswordLogin}>{t('login')}</button>
                        <button className='btnLoginGoogle' onClick={handleGoogleSignIn}>
                            {t('login-with')} <FontAwesomeIcon className='iconLoginGoogle' icon={faGoogle} />
                        </button>
                        <Link to="/signup">
                            <p className='loginLink'>{t('notAccountGoToSignup')}</p>
                        </Link>
                        <Link to="/loginpin">
                            <p className='loginLink'>{t('go-to-login-pin')}</p>
                        </Link>
                    </div>
                </div>
                {loginError && <p className="loginError">{loginError}</p>}
            </div>
        </div>
    );
}

export default Login;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import englishTranslations from './translations/english.json';
import spanishTranslations from './translations/spanish.json';
import catalanTranslations from './translations/catalan.json';
import galicianTranslations from './translations/galego.json';
import basqueTranslations from './translations/euskera.json';

/**
 * Retrieves the current language from localStorage.
 * @returns {string | undefined} The language code if it exists, otherwise undefined.
 */
export const getLanguage = () => {
    try {
        const cachedLanguage = localStorage.getItem('language');
        return cachedLanguage !== null ? cachedLanguage : undefined;
    } catch (error) {
        console.error('Error retrieving language from localStorage', error);
    }
};

/**
 * Provides a list of available languages.
 * @returns {Array} An array of language objects with label and value properties.
 */
export const getLanguages = () => [
    { label: 'Español', value: 'es' },
    { label: 'Català', value: 'ca' },
    { label: 'Galego', value: 'gl' },
    { label: 'Euskera', value: 'eu' },
    { label: 'English', value: 'en' },
];

/**
 * Gets the language name based on the language code.
 * @param {string} languageCode - The code of the language.
 * @returns {string} The name of the language.
 */
export const getLanguageNameFromLang = (languageCode) => {
    const languageOption = getLanguages().find(option => option.value === languageCode);
    return languageOption ? languageOption.label : '';
};

/**
 * Saves the selected language to localStorage.
 * @param {string} languageCode - The code of the language to save.
 */
export const saveLanguage = (languageCode) => {
    try {
        localStorage.setItem('lang', JSON.stringify(languageCode));
    } catch (error) {
        console.error('Error saving language to localStorage', error);
    }
};

// Initialize i18n with react-i18next
i18n
    .use(initReactI18next)
    .init({
        fallbackLng: 'es', // Default language
        resources: {
            en: { translation: englishTranslations },
            es: { translation: spanishTranslations },
            ca: { translation: catalanTranslations },
            gl: { translation: galicianTranslations },
            eu: { translation: basqueTranslations },
        },
        interpolation: {
            escapeValue: false, // React already does escaping
        },
    });

export default i18n;


import React from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import './BuyMeACoffee.css';
import paypalQr from '../assets/qr-paypal.png';


function BuyMeACoffee({ user, notification, setNotification, loading, setLoading, handleLogout }) {
  const { t } = useTranslation();


  return (
    <div className='buymeacoffee-div'>
      <h1>{'☕ ' + t('buymeacoffee') + ' ☕'}</h1>
      <br></br>
      <p>{t('buymeacoffee-description')}</p>
      <br></br>
      <p className='buymeacoffee-description2'>{t('buymeacoffee-description2')}</p>

      <br></br>
      <button className='buymeacoffee-btn'>
        <a href="https://www.paypal.com/ncp/payment/WXDCVCNDA4Z68" target="_blank" rel='noreferrer'>{'☕ ' + t('buymeacoffee-btn') + ' ☕'}</a>
      </button>
      <br></br>
      <img alt="icon" className="" src={paypalQr} />

    </div>
  );
};

export default BuyMeACoffee;

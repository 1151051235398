import React, { useState, useEffect, useRef } from "react";
//import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
//import { getAllFruitImage } from '../../utils/FruitImgUtil.js';
import './CatchFruits.css';
import cart from '../../assets/games/catchfruits/cart.png';
import sky from '../../assets/games/catchfruits/sky.png';
import { useTranslation } from 'react-i18next';
import { getAllFruitImage } from '../../utils/FruitImgUtil.js';

function CatchFruits({ user, notification, setNotification, loading, setLoading, handleLogout }) {
  const { t } = useTranslation();

  const allFruits = getAllFruitImage();
  const [basketX, setBasketX] = useState(200); // Posición de la cesta
  const [fruits, setFruits] = useState([]); // Lista de frutas cayendo
  const [score, setScore] = useState(0);
  const [lives, setLives] = useState(3);
  const [speed, setSpeed] = useState(2);
  const gameRef = useRef(null);


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "ArrowLeft") setBasketX((prev) => Math.max(prev - 20, 0));
      if (event.key === "ArrowRight") setBasketX((prev) => Math.min(prev + 20, 400));
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, []);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setBasketX((prev) => event.clientX);
     
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  const addFruit = () => {
    const fruitData = allFruits[Math.floor(Math.random() * allFruits.length)];

    setFruits((prev) => [
      ...prev,
      { id: Date.now(), x: (Math.random() * 100) + '%', y: 0, type: "fruit", img: fruitData.img },
    ]);
  }

  useEffect(() => {
    const intervalAddFruit = setInterval(() => {
      addFruit();
    }, 1000);

    return () => clearInterval(intervalAddFruit);
  }, []);

  const removeFruitsFloor = () => {
    setFruits((prevFruits) =>
      prevFruits.filter((fruit) => {
        if (fruit.y > 450 && fruit.x > basketX - 30 && fruit.x < basketX + 50) {
          setScore((prevScore) => prevScore + (fruit.type === "fruit" ? 10 : -5));
          return false; // Eliminar fruta atrapada
        }
        return true;
      })
    );
  }

  useEffect(() => {
    const intervalRemoveFruitsFloor = setInterval(() => {
      removeFruitsFloor();
    }, 100);

    return () => clearInterval(intervalRemoveFruitsFloor);
  }, []);


  useEffect(() => {
    const gameLoop = () => {
      setFruits((prevFruits) =>
        prevFruits
          .map((fruit) => ({ ...fruit, y: fruit.y + speed })) // Hacer que las frutas caigan
          .filter((fruit) => fruit.y < 500) // Eliminar frutas que caen fuera de la pantalla
      );

      gameRef.current = requestAnimationFrame(gameLoop);
    };

    gameRef.current = requestAnimationFrame(gameLoop);
    return () => cancelAnimationFrame(gameRef.current);
  }, []);

  /* useEffect(() => {
    setFruits((prevFruits) =>
      prevFruits.filter((fruit) => {
        if (fruit.y > 450 && fruit.x > basketX - 30 && fruit.x < basketX + 50) {
          setScore((prevScore) => prevScore + (fruit.type === "fruit" ? 10 : -5));
          return false; // Eliminar fruta atrapada
        }
        return true;
      })
    );
  }, [basketX, fruits]); */

  return (
    <div style={{ width: '80%', height: 'calc(100vh - 180px)', backgroundImage: `url(${sky})`, backgroundSize: "contain", position: "relative", overflow: "hidden", margin: "0 auto" }}>
      {fruits.map((fruit) => (
        <img
          key={fruit.id}
          src={fruit.img}
          style={{
            position: "absolute",
            left: fruit.x,
            top: fruit.y,
            width: 50,
            height: 50,
          }}
        />
      ))}
      <div
        style={{
          position: "absolute",
          bottom: 40,
          left: `calc(${basketX}px - 10% - 50px)`,
          width: 50,
          height: 30,
          background: "brown",
          borderRadius: "10px",
        }}
      />
      <div style={{ position: "absolute", top: 10, left: 10, fontSize: 20 }}>{t('points') + ': ' + score}</div>
      <div style={{ position: "absolute", top: 30, left: 10, fontSize: 20 }}>{t('lives') + ': ' + lives}</div>
    </div>
  );
};


export default CatchFruits;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import './Awards.css';
import {
  faEdit, faTrash, faSortNumericUp, faSortNumericDown,
  faSortAlphaUp, faSortAlphaDown, faAdd, faImage,
  faSave, faCancel,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import handleResponseError, { addAward, removeAward, editAward } from '../utils/APIUtils';
import Modal from '../components/Modal.js';
import poster from '../assets/poster/poster.png';
import star from '../assets/poster/star.png';
import jsPDF from 'jspdf';

/**
 * The Awards component displays a list of all available awards. It allows the user
 * to add new awards, edit existing awards and delete awards.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.user - The logged-in user.
 * @param {Array} props.awards - An array of all available awards.
 * @param {Function} props.setAwardsData - A function to update the awards state.
 * @param {Object} props.notification - The current notification to display.
 * @param {Function} props.setNotification - A function to update the notification.
 * @param {boolean} props.loading - Whether the component is currently loading.
 * @param {Function} props.setLoading - A function to update the loading state.
 * @param {Function} props.handleLogout - A function to handle logout.
 */
function Awards({ user, awards, setAwardsData, notification, setNotification, loading, setLoading, handleLogout }) {
  const { t } = useTranslation();
  const [newAwardTitle, setNewAwardTitle] = useState('');
  const [newAwardPoints, setNewAwardPoints] = useState('');
  const [newAwardType, setNewAwardType] = useState('I');
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [selectedAward, setSelectedAward] = useState(null);
  const [sortMode, setSortMode] = useState('AU');

  /**
   * Loads an image from a given source URL and returns a promise that resolves with the image element.
   * 
   * @param {string} src - The URL of the image to load.
   * @returns {Promise<HTMLImageElement>} A promise that resolves with the image element.
   */
  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
    });
  };

  /**
   * Sorts the awards based on the specified mode.
   * 
   * @param {string} mode - The sorting mode to use. 
   * Modes include:
   * 'AU' for ascending by title,
   * 'AD' for descending by title,
   * 'NU' for ascending by points,
   * 'ND' for descending by points.
   */
  const sortAwards = (mode) => {
    // Set the current sort mode
    setSortMode(mode);

    // Create a copy of the awards array to sort
    const sortedAwards = [...awards];

    // Perform sorting based on the selected mode
    switch (mode) {
      case 'AU':
        // Sort awards by title in ascending order
        sortedAwards.sort((a, b) => a.title.localeCompare(b.title));
        break;
      case 'AD':
        // Sort awards by title in descending order
        sortedAwards.sort((a, b) => b.title.localeCompare(a.title));
        break;
      case 'NU':
        // Sort awards by points in ascending order
        sortedAwards.sort((a, b) => a.points - b.points);
        break;
      case 'ND':
        // Sort awards by points in descending order
        sortedAwards.sort((a, b) => b.points - a.points);
        break;
      default:
        // No sorting if mode is unrecognized
        break;
    }

    // Update the awards data with the sorted array
    setAwardsData(sortedAwards);
  };

  /**
   * Generates a PDF poster with the awards.
   * @async
   * @returns {Promise<void>}
   */
  const generatePoster = async () => {
    const doc = new jsPDF();

    try {
      // Load the background image
      const imgBackground = await loadImage(poster);

      // Set the background image
      doc.addImage(imgBackground, 'PNG', 0, 0, 210, 297);

      // Set the current height
      let currentHeight = 60;

      // Iterate over the awards and add them to the PDF
      for (const award of awards) {
        // If the current height is greater than 240, add a new page
        if (currentHeight > 240) {
          doc.addPage();
          // Set the background image
          doc.addImage(imgBackground, 'PNG', 0, 0, 210, 297);
          // Set the current height
          currentHeight = 60;
        }

        // Add the star
        doc.setFontSize(20);
        doc.addImage(star, 'PNG', 25, currentHeight - 11, 17, 17);

        // Calculate the x position of the text
        let xPosition = 30;
        if (award.points < 10) xPosition = 32;
        if (award.points > 99) xPosition = 28;
        // Add the points
        doc.text(String(award.points), xPosition, currentHeight);

        // Split the text into lines
        const textLines = doc.splitTextToSize(award.title, 130);

        // Set the initial height
        let subHeight = currentHeight;

        // Iterate over the lines of text
        // eslint-disable-next-line
        textLines.forEach(line => {
          // Calculate the position of the text
          const position = (100 - doc.getTextWidth(line)) / 2 + 60;
          // Add the text
          doc.text(line, position, subHeight);
          // Increase the height
          subHeight += 10;
          // Increase the current height
          currentHeight += 10;
        });

        // Increase the current height
        currentHeight += 10;
      }

      // Save the PDF
      doc.save('FRUITITY-POSTER.pdf');
    } catch (error) {
      // Handle the error
      console.error('PDF generation failed:', error);
      alert(`Error: ${error.message}`);
    }
  };

  /**
   * Shows a confirmation modal when deleting an award.
   * @param {Object} award The award to be deleted.
   */
  const handleDeleteAward = (award) => {
    // Set the selected award to be deleted
    setSelectedAward(award);
    // Show the confirmation modal
    setShowModalDelete(true);
  };

  /**
   * Confirms the deletion of an award.
   * Sends a request to remove the award and updates the award data if successful.
   */
  const handleConfirmDeleteAward = async () => {
    // Set loading state to true
    setLoading(true);

    // Send a request to remove the selected award
    const response = await removeAward(user.token, selectedAward._id);

    // If the response is successful, update the awards data
    if (response?.ok) {
      setAwardsData(awards.filter((award) => award._id !== selectedAward._id));
    } else {
      // Handle any errors during the request
      handleResponseError(response, t, handleLogout, setNotification);
    }

    // Reset loading state, selected award, and hide delete modal
    setLoading(false);
    setSelectedAward(null);
    setShowModalDelete(false);
  };

  /**
   * Handles the cancellation of deleting an award.
   * This function is called when the user clicks on the "Cancel" button
   * in the delete award modal.
   */
  const handleCancelDelete = () => {
    // Hide the delete award modal
    setShowModalDelete(false);
    // Reset the selected award state
    setSelectedAward(null);
  };


  /**
   * Handles the addition of a new award.
   * This function is called when the user clicks on the "Add Award" button.
   * 
   * @returns {Promise<void>}
   */
  const handleAddAward = async () => {
    setLoading(true);
    const response = await addAward(user.token, newAwardTitle, newAwardPoints, newAwardType);

    if (response?.ok) {
      // Add the new award to the list
      setAwardsData([...awards, response.data]);
      // Reset the new award title and points
      setNewAwardTitle('');
      setNewAwardPoints('');
    } else {
      // Handle any errors during the addition of a new award
      handleResponseError(response, t, handleLogout, setNotification);
    }

    setLoading(false);
  };

  /**
   * Sets the selected award to the given award and shows the edit modal.
   * This function is called when the user clicks on the edit button of an award.
   * 
   * @param {Object} award - The award to be edited.
   */
  const handleEditAward = (award) => {
    setSelectedAward(award);
    setShowModalEdit(true);
  };

  /**
   * Confirms the edit of an award.
   * Sends a request to edit the award with the provided parameters.
   * Updates the awards data if the request is successful.
   * 
   * @param {Object} params - The new award parameters.
   * @param {string} params.title - The new title for the award.
   * @param {number} params.points - The new points for the award.
   * @param {type} params.type - If it is for individual or for class.
   */
  const handleConfirmEdit = async (params) => {
    // Set loading state to true
    console.log(params.type)
    setLoading(true);

    // Check if there is a selected award
    if (selectedAward) {
      // Send a request to edit the award
      const response = await editAward(user.token, selectedAward._id, params.title, params.points, params.type);

      // If the response is successful, update the awards data
      if (response?.ok) {
        setAwardsData(awards.map(award =>
          award._id === response.data._id ? response.data : award
        ));
      } else {
        handleResponseError(response, t, handleLogout, setNotification);
      }
    }

    // Reset loading state, selected award, and hide edit modal
    setLoading(false);
    setSelectedAward(null);
    setShowModalEdit(false);
  };

  /**
   * Cancels the editing of an award.
   * Sets the selected award to null and hides the edit modal.
   */
  const handleCancelEdit = () => {
    // Reset the selected award to null
    setSelectedAward(null);
    // Hide the edit modal
    setShowModalEdit(false);
  };

  return (
    <div>
      {showModalEdit && (
        <Modal
          notification={t('modal-edit-award')}
          confirmText={t('modal-btn-edit-ok')}
          cancelText={t('modal-btn-edit-cancel')}
          onConfirm={handleConfirmEdit}
          onCancel={handleCancelEdit}
          fields={[
            { name: 'title', type: 'text', placeholder: t('award-name'), mandatory: true, value: selectedAward?.title || '-' },
            { name: 'points', type: 'number', placeholder: t('award-points'), mandatory: true, value: selectedAward?.points || '-' },
            {
              name: 'type', type: 'toggleswitch', mandatory: true, value: selectedAward?.type || 'I',
              toggleSwitchData: {
                titleOption1: t('award-type-individual'),
                titleOption2: t('award-type-group'),
                valueOption1: 'I',
                valueOption2: 'G'
              }
            },
          ]}
          confirmIcon={faSave}
          cancelIcon={faCancel}
        />
      )}

      {showModalDelete && (
        <Modal
          notification={t('modal-delete-award', { awardName: selectedAward?.title || '-' })}
          confirmText={t('modal-btn-delete-ok')}
          cancelText={t('modal-btn-delete-cancel')}
          onConfirm={handleConfirmDeleteAward}
          onCancel={handleCancelDelete}
          confirmIcon={faTrash}
          cancelIcon={faCancel}
        />
      )}

      <h1>{t('awards')}</h1>
      <div className='divAwards'>
        {(!awards || awards.length === 0) ? (
          <p className='pNoAwards'>{t('no-awards')}</p>
        ) : (
          <>
            <div className='divGeneratePoster'>
              <button onClick={generatePoster}>
                <FontAwesomeIcon icon={faImage} />
                {' ' + t('btn-generate-poster')}
              </button>
            </div>

            <div className='divSortAwards'>
              {t('sortby')}
              <FontAwesomeIcon className={`awardIconSort ${sortMode === 'AU' && 'awardIconSortSelected'}`} icon={faSortAlphaUp} onClick={() => sortAwards('AU')} />
              <FontAwesomeIcon className={`awardIconSort ${sortMode === 'AD' && 'awardIconSortSelected'}`} icon={faSortAlphaDown} onClick={() => sortAwards('AD')} />
              <FontAwesomeIcon className={`awardIconSort ${sortMode === 'NU' && 'awardIconSortSelected'}`} icon={faSortNumericUp} onClick={() => sortAwards('NU')} />
              <FontAwesomeIcon className={`awardIconSort ${sortMode === 'ND' && 'awardIconSortSelected'}`} icon={faSortNumericDown} onClick={() => sortAwards('ND')} />
            </div>

            <table className='tableAwards'>
              <thead>
                <tr className='trTitle'>
                  <td className='tdTitleTitle'><h3>{t('award-name')}</h3></td>
                  <td className='tdTitlePoints'><h3>{t('award-points')}</h3></td>
                  <td className='tdEdit'></td>
                  <td className='tdDelete'></td>
                </tr>
              </thead>
              <tbody>
                {awards.map((award) => (
                  <tr className='trAward' key={award._id}>
                    <td className='tdAwardTitle'><p>{award.title}</p></td>
                    <td className='tdAwardPoints'><p>{award.points}</p></td>
                    <td className='tdAwardType'>
                      {award.type === 'I' ? 
                        <FontAwesomeIcon className='iconFruititySmall iconAwardType' icon={faUser} />
                        :
                        <FontAwesomeIcon className='iconFruititySmall iconAwardType' icon={faUsers} />
                      }
                    </td>
                    <td className='tdAwardEdit'>
                      <button onClick={() => handleEditAward(award)}>
                        <FontAwesomeIcon className='iconFruititySmall' icon={faEdit} />
                      </button>
                    </td>
                    <td className='tdAwardDelete'>
                      <button onClick={() => handleDeleteAward(award)}>
                        <FontAwesomeIcon className='iconFruititySmall' icon={faTrash} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>

      <h1>{t('new-awards')}</h1>
      <div className='divNewAwardInputs'>
        <input
          type="text"
          placeholder={t('award-name')}
          value={newAwardTitle}
          onChange={(e) => setNewAwardTitle(e.target.value)}
        />
        <input
          type="number"
          placeholder={t('award-points')}
          value={newAwardPoints}
          onChange={(e) => setNewAwardPoints(e.target.value)}
        />

        <div className='toggleSwitch'>
          <div onClick={() => setNewAwardType('I')} className={newAwardType === 'I'? 'toggleSwitchSelected' : 'toggleSwitchUnselected'}>
            {t('award-type-individual')}
          </div>
          <div onClick={() => setNewAwardType('G')} className={newAwardType === 'G'? 'toggleSwitchSelected' : 'toggleSwitchUnselected'}>
            {t('award-type-group')}
          </div>
        </div>
      </div>
      <div className='divNewAwardBtn'>
        <button onClick={handleAddAward}
          disabled={!newAwardTitle || !newAwardPoints}
          className={`${(!newAwardTitle || !newAwardPoints) && 'buttonDisabled'}`}
        >
          <FontAwesomeIcon className='iconFruitity' icon={faAdd} />
        </button>
      </div>
    </div>
  );
};

export default Awards;


import React, { useState, useEffect } from 'react';
import './Modal.css';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

/**
 * A modal component that displays a notification and an optional set of fields.
 *
 * If the fields are mandatory, the user must fill them before confirming.
 * If the user clicks the confirm button and there are empty mandatory fields,
 * an error message is displayed.
 *
 * @param {string} notification The notification to display on the modal.
 * @param {Array<{name: string, type: string, placeholder: string, mandatory: boolean}>} fields An array of field objects.
 * @param {string} confirmText The text of the confirm button.
 * @param {string} cancelText The text of the cancel button.
 * @param {FontAwesomeIcon} confirmIcon The icon of the confirm button.
 * @param {FontAwesomeIcon} cancelIcon The icon of the cancel button.
 * @param {function} onConfirm Called when the user clicks the confirm button.
 * @param {function} onCancel Called when the user clicks the cancel button.
 */
function Modal({ notification, fields, confirmText, cancelText, confirmIcon, cancelIcon, onConfirm, onCancel }) {
    const { t } = useTranslation();
    const [inputs, setInputs] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
        // Initialize the inputs with the field values if they are provided
        const initialInputs = fields?.reduce((acc, field) => {
            acc[field.name] = field.value || '';
            return acc;
        }, {});
        setInputs(initialInputs || {});
    }, [fields]);

    /**
     * Handles the change event of an input field.
     * Updates the state of the inputs with the new value of the field.
     * @param {Event} event The event object.
     */
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    };

    const handleInputToggleSwitchChange = (name, value) => {
        setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    }

    /**
     * Handles the click event of the confirm button.
     * Checks if there are empty mandatory fields and displays an error message if there are.
     * If there are no empty mandatory fields, calls the onConfirm function with the inputs.
     */
    const handleConfirm = () => {
        const hasError = fields?.some(field => field.mandatory && !inputs[field.name]);
        if (hasError) {
            setError(t('modal-error-fill-fields'));
        } else {
            onConfirm(inputs);
        }
    };

    return (
        <div className="divModalOutside">
            <div className="divModalInside">
                <button className="btnModalClose" onClick={onCancel}>
                    <FontAwesomeIcon className="iconFruitity iconFruititySmall" icon={faClose} />
                </button>
                <h3>{notification}</h3>
                <div className="divModalInputs">
                    {fields?.map(({ name, type, placeholder, mandatory, toggleSwitchData }) => (
                        <div key={name}>
                            {['toggleswitch'].includes(type) ? (
                                <div className='toggleSwitch'>
                                    <div className={inputs[name] === toggleSwitchData.valueOption1 ? 'toggleSwitchSelected' : 'toggleSwitchUnselected'} onClick={() => handleInputToggleSwitchChange(name, toggleSwitchData.valueOption1)}>
                                        {toggleSwitchData.titleOption1}
                                    </div>
                                    <div className={inputs[name] === toggleSwitchData.valueOption2 ? 'toggleSwitchSelected' : 'toggleSwitchUnselected'} onClick={() => handleInputToggleSwitchChange(name, toggleSwitchData.valueOption2)}>
                                        {toggleSwitchData.titleOption2}
                                    </div>
                                </div>
                            ) : (
                                <input
                                    type={type}
                                    name={name}
                                    placeholder={`${placeholder || name}${mandatory ? '*' : ''}`}
                                    id={name}
                                    value={inputs[name] || ''}
                                    onChange={handleInputChange}
                                />
                            )}
                        </div>
                    ))}
                    {error && <p className="modalError">{error}</p>}
                </div>
                <div className="divModalButtons">
                    <button onClick={handleConfirm}>
                        {confirmIcon && <FontAwesomeIcon icon={confirmIcon} />}
                        {confirmIcon && '  '}
                        {confirmText}
                    </button>
                    <button onClick={onCancel}>
                        {cancelIcon && <FontAwesomeIcon icon={cancelIcon} />}
                        {cancelIcon && '  '}
                        {cancelText}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Modal;
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './language/i18n.js';

import Main from './pages/Main.js';

import { getLanguage } from './language/i18n.js';

import { AuthProvider } from './context/AuthContext';
import './App.css';
import '@fontsource/montserrat';

/**
 * The main App component.
 * @returns {React.ReactElement} The JSX element to render.
 */
function App() {
  // Get the i18n object from the useTranslation hook.
  const { i18n } = useTranslation();

  // Set the language when the component mounts.
  useEffect(() => {
    // Get the language from local storage.
    const lang = getLanguage();
    // If the language is set, change it to the saved language.
    if (lang) {
      i18n.changeLanguage(lang);
    }
    // Ignore the dependencies warning. The effect is only run once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // The main App component is an AuthProvider.
  return (
    <AuthProvider>
      {/* The main page to render. */}
      <Main />
    </AuthProvider>
  );
}

export default App;

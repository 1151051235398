//const IP = 'http://192.168.5.39:3001';
//const IP = 'http://localhost:3001';
const IP = process.env.REACT_APP_BACKEND_URL;
//'https://backend.fruitity.com';
//const IP = 'http://192.168.215.39:3001';
//const IP = 'http://10.2.0.190:3001';

/**
 * Sends a POST request to the specified route with given parameters.
 * 
 * @param {string} route - The API route to send the request to.
 * @param {Object} params - The parameters to include in the request body.
 * @returns {Promise<Object>} The API response object.
 */
export const getQuery = async (route, params) => {
  try {
    const response = await fetch(IP + route, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params),
    });
    const data = await response.json();

    // Check if the response contains an 'ok' property
    if (data.hasOwnProperty('ok')) {
      return data;
    } else {
      return {
        ok: false,
        error: data.error
      };
    }
  } catch (error) {
    // Handle network errors
    return {
      ok: false,
      error: 'net-error'
    };
  }
};

/**
 * Sends a GET request to the '/userinfo' route to get the user information.
 * 
 * @param {string} token - The user token to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const getInfoUser = async (token) => {
  return await getQuery('/userinfo', {token});
}

/**
 * Sends a GET request to the '/classes' route to get the classes associated with the user.
 * 
 * @param {string} token - The user token to include in the request.
 * @returns {Promise<Object>} The API response object containing the classes associated with the user.
 */
export const getDataFromUser = async (token) => {
  return await getQuery('/classes', {token});
};

/**
 * Sends a GET request to the '/schools' route to get the schools associated with the user.
 * 
 * @param {string} token - The user token to include in the request.
 * @returns {Promise<Object>} The API response object containing the schools associated with the user.
 */
export const getSchoolsData  = async (token) => {
  return await getQuery('/schools', {token});
};

/**
 * Sends a GET request to the '/awards' route to get the awards associated with the user.
 * 
 * @param {string} token - The user token to include in the request.
 * @returns {Promise<Object>} The API response object containing the awards associated with the user.
 */
export const getAwards = async(token) => {
  return await getQuery('/awards', {token});
};

/**
 * Sends a POST request to the '/login' route to login the user.
 * 
 * @param {string} email - The user email to include in the request.
 * @param {string} password - The user password to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const getUserLogin = async (email, password) => {
  return await getQuery('/login', {email, password});
};

/**
 * Sends a POST request to the '/sendpintouser' route to send a pin to the user.
 * 
 * @param {string} email - The user email to include in the request.
 * @returns {Promise<Object>} The API response object indicating the status of the request.
 */
export const sendPinToUser = async (email) => {
  return await getQuery('/sendpintouser', {email});
};

/**
 * Sends a POST request to the '/loginpin' route to login the user with a pin.
 * 
 * @param {string} email - The user email to include in the request.
 * @param {string} pin - The pin to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const loginUserWithPin = async (email, pin) => {
  return await getQuery('/loginpin', {email, pin});
}

/**
 * Sends a POST request to the '/logingoogle' route to login the user with a Google account.
 * 
 * @param {string} userGoogleAccessToken - The access token from the Google account to include in the request.
 * @param {string} userGoogleName - The name of the user to include in the request.
 * @param {string} userGoogleEmail - The email of the user to include in the request.
 * @param {string} language - The language of the user to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const getUserLoginGoogle = async (userGoogleAccessToken, userGoogleName, userGoogleEmail, language) => {
  return await getQuery('/logingoogle', {userGoogleAccessToken, userGoogleName, userGoogleEmail, language});
};

/**
 * Sends a POST request to the '/signup' route to signup the user.
 * 
 * @param {string} name - The name of the user to include in the request.
 * @param {string} surname - The surname of the user to include in the request.
 * @param {string} email - The email of the user to include in the request.
 * @param {string} password - The password of the user to include in the request.
 * @param {string} language - The language of the user to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const getUserSignup = async (name, surname, email, password, language) => {
  return await getQuery('/signup', {name, surname, email, password, language});
}

/**
 * Sends a POST request to the '/sendactivationemail' route to send an activation email to the user.
 * 
 * @param {string} token - The user token to include in the request.
 * @returns {Promise<Object>} The API response object indicating the status of the request.
 */
export const sendActivationEmail = async(token) => {
  return await getQuery('/sendactivationemail', {token});
}


/**
 * Sends a POST request to the '/edituser' route to edit the user information.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} name - The name of the user to include in the request.
 * @param {string} surname - The surname of the user to include in the request.
 * @param {boolean} isNewPassword - Whether the password is new or not to include in the request.
 * @param {string} newPassword - The new password to include in the request.
 * @param {string} language - The language of the user to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const editUser = async (token, name, surname, isNewPassword, newPassword, language) => {
  return await getQuery('/edituser', {token, name, surname, isNewPassword, newPassword, language});
};

/**
 * Sends a POST request to the '/removeuser' route to remove the user.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} userId - The user id to include in the request.
 * @returns {Promise<Object>} The API response object indicating the status of the request.
 */
export const removeUser = async (token, userId) => {
  return await getQuery('/removeuser', {token, userId});
}

/**
 * Sends a POST request to the '/addmeal' route to add a meal to the user.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} date - The date of the meal to include in the request.
 * @param {string} studentId - The student id to include in the request.
 * @param {string} fruitId - The fruit id to include in the request.
 * @param {number} points - The points of the meal to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const addMealToUser = async (token, classId, date, studentId, fruitId, points) => {
  return await getQuery('/addmeal', { token, classId, date, studentId, fruitId, points});
};


/**
 * Sends a POST request to the '/addawardtostudent' route to add an award to the student.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} date - The date of the award to include in the request.
 * @param {string} studentId - The student id to include in the request.
 * @param {string} awardId - The award id to include in the request.
 * @param {string} awardTitle - The title of the award to include in the request.
 * @param {number} points - The points of the award to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const addAwardToStudent = async (token, classId, date, studentId, awardId, awardTitle, points) => {
  return await getQuery('/addawardtostudent', { token, classId, date, studentId, awardId, awardTitle, points});
};

/**
 * Sends a POST request to the '/addawardtogroup' route to add an award to the group.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} date - The date of the award to include in the request.
 * @param {string} awardId - The award id to include in the request.
 * @param {string} awardTitle - The title of the award to include in the request.
 * @param {number} points - The points of the award to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const addAwardToGroup = async (token, classId, date, awardId, awardTitle, points) => {
  return await getQuery('/addawardtogroup', { token, classId, date, awardId, awardTitle, points});
}

/**
 * Sends a POST request to the '/addaward' route to add an award.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} awardName - The name of the award to include in the request.
 * @param {number} awardPoints - The points of the award to include in the request.
 * @returns {Promise<Object>} The API response object containing the user information.
 */
export const addAward = async (token, awardName, awardPoints, awardType) => {
  return await getQuery('/addaward', { token, awardName, awardPoints, awardType});
};

/**
 * Sends a POST request to the '/addclass' route to add a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} schoolName - The name of the school to include in the request.
 * @param {string} className - The name of the class to include in the request.
 * @param {array} studentList - The list of students to include in the request.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const addClass = async (token, schoolName, className, studentList) => {
  return await getQuery('/addclass', { token, schoolName, className, studentList});
};

/**
 * Sends a POST request to the '/addschool' route to add a school.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} name - The name of the school to include in the request.
 * @param {string} city - The city of the school to include in the request.
 * @returns {Promise<Object>} The API response object containing the school information.
 */
export const addSchool = async (token, name, city) => {
  return await getQuery('/addschool',{ token, name, city});
};

/**
 * Sends a POST request to the '/addstudents' route to add a list of students to a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {array} list - The list of students to include in the request.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const addStudentsToClass = async (token, classId, list) => {
  return await getQuery('/addstudents',{ token, classId, list});
}

/**
 * Sends a POST request to the '/inviteteacher' route to invite a teacher to a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} email - The email of the teacher to include in the request.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const inviteTeacherToClass = async (token, classId, email) => {
  return await getQuery('/inviteteacher',{ token, classId, email});
}

/**
 * Sends a POST request to the '/removeinviteteacher' route to remove a teacher invitation
 * from a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} email - The email of the teacher to include in the request.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const removeInvitationTeacherToClass = async (token, classId, email) => {
  return await getQuery('/removeinviteteacher',{ token, classId, email});
}

/**
 * Sends a POST request to the '/removeclass' route to remove a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const removeClass = async(token, classId) => {
  return await getQuery('/removeclass',{ token, classId});
}

/**
 * Sends a POST request to the '/editclass' route to edit a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} className - The name of the class to include in the request.
 * @param {string} schoolName - The name of the school to include in the request.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const editClass = async(token, classId, className, schoolName) => {
  return await getQuery('/editclass',{ token, classId, className, schoolName});
}

/**
 * Sends a POST request to the '/archiveclass' route to archive or unarchive a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {boolean} archived - The archived status to set for the class.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const archiveClass = async(token, classId, archived) => {
  return await getQuery('/archiveclass',{ token, classId, archived});
}

/**
 * Sends a POST request to the '/editaward' route to edit an award.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} awardId - The award id to include in the request.
 * @param {string} awardTitle - The title of the award to include in the request.
 * @param {number} awardPoints - The points of the award to include in the request.
 * @returns {Promise<Object>} The API response object containing the award information.
 */
export const editAward = async(token, awardId, awardTitle, awardPoints, awardType) => {
  return await getQuery('/editaward',{ token, awardId, awardTitle, awardPoints, awardType});
}

/**
 * Sends a POST request to the '/removeaward' route to remove an award.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} awardId - The award id to include in the request.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const removeAward = async(token, awardId) => {
  return await getQuery('/removeaward',{ token, awardId});
}

/**
 * Sends a POST request to the '/removestudent' route to remove a student from a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} studentId - The student id to include in the request.
 * @returns {Promise<Object>} The API response object containing the class information.
 */
export const removeStudentFromClass = async(token, classId, studentId) => {
  return await getQuery('/removestudent',{ token, classId, studentId});
}

/**
 * Sends a POST request to the '/editstudent' route to edit a student's details in a class.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} studentId - The student id to include in the request.
 * @param {string} studentName - The new name of the student.
 * @param {string} avatar - The new avatar of the student.
 * @returns {Promise<Object>} The API response object.
 */
export const editStudentFromClass = async (token, classId, studentId, studentName, avatar) => {
  return await getQuery('/editstudent', { token, classId, studentId, studentName, avatar });
};

/**
 * Sends a POST request to the '/removemeal' route to remove a meal from a user.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} classId - The class id to include in the request.
 * @param {string} studentId - The student id to include in the request.
 * @param {string} mealId - The meal id to be removed.
 * @returns {Promise<Object>} The API response object.
 */
export const removeMealFromUser = async (token, classId, studentId, mealId) => {
  return await getQuery('/removemeal', { token, classId, studentId, mealId });
};

/**
 * Sends a POST request to the '/usernewsreaded' route to mark user news as read.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {Object} user - The user object.
 * @returns {Promise<Object>} The API response object.
 */
export const setNewsUserAsReaded = async (token, user) => {
  return await getQuery('/usernewsreaded', { token, user });
};

/**
 * Sends a GET request to the '/adminusers' route to retrieve admin users.
 * 
 * @param {string} token - The user token to include in the request.
 * @returns {Promise<Object>} The API response object containing admin users.
 */
export const getAdminUsers = async (token) => {
  return await getQuery('/adminusers', { token });
};

/**
 * Sends a GET request to the '/adminclasses' route to retrieve admin classes.
 * 
 * @param {string} token - The user token to include in the request.
 * @returns {Promise<Object>} The API response object containing admin classes.
 */
export const getAdminClasses = async (token) => {
  return await getQuery('/adminclasses', { token });
};

/**
 * Sends a GET request to the '/adminnews' route to retrieve admin news.
 * 
 * @param {string} token - The user token to include in the request.
 * @returns {Promise<Object>} The API response object containing admin news.
 */
export const getAdminNews = async (token) => {
  return await getQuery('/adminnews', { token });
};

/**
 * Sends a POST request to the '/adminaddnew' route to add a new admin news item.
 * 
 * @param {string} token - The user token to include in the request.
 * @param {string} title - The title of the news.
 * @param {string} titleCA - The title of the news in Catalan.
 * @param {string} descriptionCA - The description of the news in Catalan.
 * @param {string} titleES - The title of the news in Spanish.
 * @param {string} descriptionES - The description of the news in Spanish.
 * @param {string} titleEN - The title of the news in English.
 * @param {string} descriptionEN - The description of the news in English.
 * @param {string} titleGL - The title of the news in Galician.
 * @param {string} descriptionGL - The description of the news in Galician.
 * @param {string} titleEU - The title of the news in Basque.
 * @param {string} descriptionEU - The description of the news in Basque.
 * @returns {Promise<Object>} The API response object.
 */
export const adminAddNew = async (token, title, titleCA, descriptionCA, titleES, descriptionES, titleEN, descriptionEN, titleGL, descriptionGL, titleEU, descriptionEU) => {
  return await getQuery('/adminaddnew', { token, title, titleCA, descriptionCA, titleES, descriptionES, titleEN, descriptionEN, titleGL, descriptionGL, titleEU, descriptionEU });
};

/**
 * Handles API response errors.
 *
 * @param {Object} data - The API response data.
 * @param {Function} handleLogout - A function to handle logout.
 * @param {Function} setNotification - A function to set a notification.
 */
export default function handleResponseError(data, t, handleLogout, setNotification) {
  if (data && data.error && data.error === 'invalid-token') {
    handleLogout();
  } else {
    setNotification({ msg: t(data.error), type: 'ERR' });
  }
}
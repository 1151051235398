import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import { getAllFruitImage } from '../../utils/FruitImgUtil.js';
import './MemoryFruits.css';
import fruit_default from '../../assets/fruits/default.png';

function MemoryFruits({ user, notification, setNotification, loading, setLoading, handleLogout }) {
  const { t } = useTranslation();

  const [fruits, setFruits] = useState([]);
  const [firstSelection, setFirstSelection] = useState(null);
  const [secondSelection, setSecondSelection] = useState(null);
  const [successes, setSuccesses] = useState([]);
  const [numberFruits, setNumberFruits] = useState(5);
  const [started, setStarted] = useState(false);
  const [theWidth, setTheWidth] = useState(200);




  const handleInputChange = (event) => {
    const newNumber = parseInt(event.target.value);
    setNumberFruits(newNumber);
  };

  const startGame = () => {
    setSuccesses([]);
    setFirstSelection(null);
    setSecondSelection(null);
    const theFruits = getAllFruitImage();
    const randomFruits = [...theFruits].sort(() => Math.random() - 0.5).slice(0, numberFruits);
    const doubledFruits = randomFruits.concat(randomFruits);
    const shuffledFruits = doubledFruits.sort(() => Math.random() - 0.5);


    let windowWidth = window.innerWidth;
    let maxWidth = 200;
    if(window.innerWidth <= 767) maxWidth = 150;
    for (let w = maxWidth; w > 20; w--) {
      if (shuffledFruits.length % Math.floor(windowWidth / w) === 0) {
        setTheWidth(w);
        console.log('The width is', w);
        break;
      }
    }

    setFruits(shuffledFruits);
    setStarted(true);
  }

  const startAgain = () => {
    setStarted(false);
  }

  const handleClickCard = (index, image) => {
    if (!firstSelection) {
      setFirstSelection([index, image]);
    } else {
      if (!secondSelection) {
        setSecondSelection([index, image]);

        if (firstSelection[1] === image) {
          let theSuccesses = [...successes];
          theSuccesses.push(firstSelection[0]);
          theSuccesses.push(index);
          setSuccesses(theSuccesses);
          setFirstSelection(null);
          setSecondSelection(null);
        } else {
          setTimeout(() => {
            setFirstSelection(null);
            setSecondSelection(null);
          }, 1000);
        }
      }
    }
  }

  const isFlipped = (index) => {
    if (successes.includes(index)) return true;
    if (firstSelection && firstSelection[0] === index) return true;
    if (secondSelection && secondSelection[0] === index) return true;
    return false;
  }

  return (
    <div className='memoryfruits'>
      <h1>{t('memoryfruits')}</h1>
      {
        !started &&
        <div className='pregame'>
          <p>{t('indicate_number_pairs')}</p>
          <input max={26} type="number" value={numberFruits} onChange={handleInputChange} />
          <button onClick={() => startGame()}>{t('start')}</button>
        </div>
      }

      {started &&
        <button onClick={() => startAgain()}>{t('start_again')}</button>
      }
      {started && fruits.length === successes.length &&
        <h1 className='win_title'>{t('you_win')}</h1>}
      {started && fruits &&
        <div className='cards'>
          {
            fruits.map((fr, index) => (
              <div className={`card flip-card ${isFlipped(index) ? 'flipped' : ''}`} key={index} style={{ width: (theWidth - 20) + 'px', height: (theWidth - 20) + 'px' }} onClick={() => handleClickCard(index, fr.img)}>
                <div className="flip-card-inner">
                  <div className='flip-card-front'>
                    <img src={fruit_default} alt={"fruit"} />
                  </div>
                  <div className='flip-card-back'>
                    <img src={fr.img} alt={"fruit"} />
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      }
    </div>
  );
};

export default MemoryFruits;

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, eu, gl } from 'date-fns/locale'; // Importa el idioma español desde date-fns
import { es } from 'date-fns/locale'; // Importa el idioma español desde date-fns
import { ca } from 'date-fns/locale'; // Importa el idioma español desde date-fns

import './Calendar.css';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { getLanguage } from '../language/i18n.js';

/**
 * Component to render a calendar with the possibility of selecting a date.
 * @prop {Date} date The current date to be displayed
 * @prop {function} setDate The function to be called when the date changes
 */
function Calendar({ date, setDate }) {
  const { t } = useTranslation();

  /**
   * Function to be called when the user wants to go back to the previous day
   */
  const handlePreviousDay = () => {
    setDate(new Date(date.getTime() - 86400000));
  };

  /**
   * Function to be called when the user wants to go to the next day
   */
  const handleNextDay = () => {
    setDate(new Date(date.getTime() + 86400000));
  };

  /**
   * Function to be called when the user selects a new date
   * @param {Date} newDate The new date selected by the user
   */
  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  /**
   * Function that returns the correct locale for the calendar
   * @returns {Object} The locale object
   */
  const getCalendarLocale = () => {
    const language = getLanguage();
    switch (language) {
      case 'ca':
        return ca;
      case 'es':
        return es;
      case 'en':
        return enGB;
      case 'gl':
        return gl;
      case 'eu':
        return eu;
      default:
        return enGB;
    }
  };

  return (
    <div className="divCalendar">
      <button onClick={handlePreviousDay}>&lt;</button>
      <DatePicker
        selected={date}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        closeOnScroll={true}
        locale={getCalendarLocale()}
        todayButton={t('today')}
        calendarStartDay={1}
      />
      <button onClick={handleNextDay}>&gt;</button>
    </div>
  );
};

export default Calendar;

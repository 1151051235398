import React, { createContext, useContext, useEffect, useState } from 'react';
import { getUserLogin, getUserLoginGoogle, loginUserWithPin } from '../utils/APIUtils';

const AuthContext = createContext();

/**
 * Provides the context for the logged in user.
 * This context is used by all the components that need to know the user's information.
 * @param {Object} props The props of the component
 * @param {React.ReactNode} children The children of the component
 * @returns {React.ReactNode} The component with the context
 */
export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  /**
   * When the component mounts, it checks if the user is already logged in
   * by checking the local storage. If the user is logged in, it sets the user
   * to the cached user.
   */
  useEffect(() => {
    const cachedUser = localStorage.getItem('user');
    if (cachedUser !== null) {
      setUser(JSON.parse(cachedUser));
    }
  }, []);

  /**
   * Logs the user in with the email and password.
   * @param {Object} data The data to be sent to the server
   * @param {string} data.email The email of the user
   * @param {string} data.password The password of the user
   * @returns {Promise<Object>} The response of the server
   */
  const loginWithPassword = async ({ email, password }) => {
    const response = await getUserLogin(email, password);
    if (response.ok) {
      setUser(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response;
  };

  /**
   * Logs the user in with the accessToken, displayName and email of the google user.
   * @param {Object} data The data to be sent to the server
   * @param {string} data.accessToken The accessToken of the user
   * @param {string} data.displayName The displayName of the user
   * @param {string} data.email The email of the user
   * @param {string} data.language The language of the user
   * @returns {Promise<Object>} The response of the server
   */
  const loginWithGoogle = async ({ accessToken, displayName, email }, language) => {
    const response = await getUserLoginGoogle(accessToken, displayName, email, language);
    if (response.ok) {
      setUser(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response;
  };

  /**
   * Logs the user in with the email and pin.
   * @param {string} email The email of the user
   * @param {string} pin The pin of the user
   * @returns {Promise<Object>} The response of the server
   */
  const loginWithPin = async (email, pin) => {
    const response = await loginUserWithPin(email, pin);
    if (response.ok) {
      setUser(response.data);
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response;
  };

  /**
   * Updates the user in the context and in the local storage.
   * @param {Object} theUser The user to be updated
   */
  const updateCurrentUser = async (theUser) => {
    setUser(theUser);
    localStorage.setItem('user', JSON.stringify(theUser));
  };

  /**
   * Logs the user out and removes the user from the local storage.
   */
  const logoutCurrentUser = () => {
    localStorage.setItem('user', null);
    setUser(null);
  };

  /**
   * Edits the user in the context and in the local storage.
   * @param {Object} userData The user to be edited
   */
  const editUserAuth = async (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        editUserAuth,
        loginWithPassword,
        loginWithGoogle,
        loginWithPin,
        updateCurrentUser,
        logoutCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
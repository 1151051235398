import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../language/i18n.js';
import './Bombfruit.css';
import { getAllFruitImage } from '../../utils/FruitImgUtil.js';
import bombfruit5 from '../../assets/games/bombfruit/bombfruit_5.png';
import bombfruit4 from '../../assets/games/bombfruit/bombfruit_4.png';
import bombfruit3 from '../../assets/games/bombfruit/bombfruit_3.png';
import bombfruit2 from '../../assets/games/bombfruit/bombfruit_2.png';
import bombfruit1 from '../../assets/games/bombfruit/bombfruit_1.png';
import bombfruit0 from '../../assets/games/bombfruit/bombfruit_0.png';
import bombfruitdisabled from '../../assets/games/bombfruit/bombfruit_disabled.png';

/**
 * @description The Bombfruit game component
 * @param {Object} props The props passed to the component
 * @param {Object} props.user The user object
 * @param {Object} props.notification The notification object
 * @param {Function} props.setNotification The function to set the notification
 * @param {Boolean} props.loading The flag to show the loading icon
 * @param {Function} props.setLoading The function to set the loading flag
 * @param {Function} props.handleLogout The function to handle the logout
 */
function Bombfruit({ user, notification, setNotification, loading, setLoading, handleLogout }) {
  const { t } = useTranslation();

  /**
   * The state variables
   */
  const [lives, setLives] = useState(5);
  const [playing, setPlaying] = useState(true);
  const [win, setWin] = useState(false);
  const [letters, setLetters] = useState([]);
  const [usedLetters, setUsedLetters] = useState([]);

  /**
   * The object with the images for the lives
   */
  const bombfruitImages = {
    5: bombfruit5,
    4: bombfruit4,
    3: bombfruit3,
    2: bombfruit2,
    1: bombfruit1,
    0: bombfruit0
  };

  /**
   * The alphabet to use for the game
   */
  const alphabet = 'abcdefghijklmnñopqrstuvwxyz'.toUpperCase().split('');

  /**
   * The array of fruits
   */
  const fruits = getAllFruitImage();

  /**
   * The function to start a new game
   */
  useEffect(() => {
    startGame();
    // eslint-disable-next-line
  }, []);

  const startGame = () => {
    setUsedLetters([]);
    setLives(5);
    setPlaying(true);
    setWin(false);

    const randomFruit = fruits[Math.floor(Math.random() * fruits.length)];
    let title = t(randomFruit.title);

    let name = title.replace(/[áàäâ]/g, 'a')
      .replace(/[éèëê]/g, 'e')
      .replace(/[íìïî]/g, 'i')
      .replace(/[óòöô]/g, 'o')
      .replace(/[úùüû]/g, 'u')
      .toUpperCase();
    setLetters(name.split(''));
  };

  /**
   * The function to handle the click on a letter
   * @param {String} letter The letter clicked
   */
  const handleClick = (letter) => {
    if (!playing) return;

    if (usedLetters.includes(letter)) {
      return;
    }

    if (!letters.includes(letter)) {
      let newLives = lives - 1;
      setLives(newLives);
      if (newLives === 0) {
        setPlaying(false);
      }
    }

    let newUsedLetters = [...usedLetters, letter];
    setUsedLetters(newUsedLetters);

    if (letters.every(l => newUsedLetters.includes(l))) {
      setPlaying(false);
      setWin(true);
    }
  };

  return (
    <div className='bombfruit'>
      <h1>{t('bombfruit')}</h1>
      <button onClick={startGame}>{t('start_again')}</button>
      <div className="bombfruit_word">
          { letters.map((letter, index) => (
            <div 
              className={`bombfruit_letter ${win ? 'bombfruit_letter_win' : !playing && !win ? 'bombfruit_letter_lose' : ''}`} 
              key={index}>
              {playing && (usedLetters.includes(letter) ? letter : '_')}
              {!playing && letter}
            </div>
          )) 
          }
      </div>
      <div className="bombfruit_image_div">
        <img className='bombfruit_img' src={(playing || !win) ? bombfruitImages[lives] : bombfruitdisabled} alt='fruit_lives'/>
        {playing && lives > 0 &&<p>{lives}</p>}
      </div>
      
     {/*  <div className='bombfruit_used_letters'>
        {t('used_letters')}
        { usedLetters.map((letter, index) => (
          <div className='bombfruit_usedletter' key={index}>
            {letter}
          </div>
        ))} 
      </div> */}
      <div className='bombfruit_keyboard'>
        { alphabet.map((letter, index) => (
          <div 
            className={`bombfruit_key ${usedLetters.includes(letter) && letters.includes(letter) ? 'bombfruit_key_correct' : usedLetters.includes(letter) ? 'bombfruit_key_wrong' : ''}`}
            key={index} onClick={() => handleClick(letter)}>
            {letter}
          </div>
        )) 
        }
      </div>  
    </div>
  );
};

export default Bombfruit;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../language/i18n.js';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { useAuth } from '../context/AuthContext';
import './Signup.css';
import fruitityIcon from '../assets/fruitity-icon.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import { getUserSignup } from '../utils/APIUtils.js';

import { auth, googleProvider } from '../firebase/firebase';
import { signInWithPopup } from 'firebase/auth';
import { getLanguages } from '../language/i18n.js';
import Selector from '../components/Selector.js';

/**
 * Signup component for handling user registration.
 * Supports registration with email and password.
 * Also allows users to select a language preference.
 * @param {Object} props The props of the component
 * @param {boolean} props.loading Whether the component is loading
 * @param {Function} props.setLoading Sets the loading state
 * @param {Object} props.notification The notification to be displayed
 * @param {Function} props.setNotification Sets the notification
 * @param {Array} props.setClassesData Sets the classes data
 * @param {string} props.selectedLanguage The selected language
 * @param {Function} props.handleLanguageChange Handles language change
 * @param {Array} props.fruitImages The fruit images to be displayed
 */
function Signup({ loading, setLoading, notification, setNotification, setClassesData, selectedLanguage, handleLanguageChange, fruitImages }) {
  // State variables to track input values and other UI states
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repassword, setRepassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showRepassword, setShowRepassword] = useState(false);

  // Errors state variables
  const [errors, setErrors] = useState({
    name: false,
    surname: false,
    email: false,
    password: false,
    repassword: false,
    language: false
  });

  // Hooks for navigation and translation
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { editUserAuth, loginWithGoogle } = useAuth();

  /**
   * Handles Google sign-in via Firebase authentication.
   */
  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      const userGoogle = auth.currentUser;

      setClassesData([]);
      loginWithGoogle(userGoogle, selectedLanguage);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  /**
   * Handles registration with email and password.
   * 
   * @async
   */
  const handleSignup = async () => {
    setLoading(true);
    const newErrors = { ...errors };
    newErrors.name = false;
    newErrors.surname = false;
    newErrors.email = false;
    newErrors.password = false;
    newErrors.repassword = false;
    newErrors.language = false;

    // Validate user input
    let correctSignup = true;

    if (name.length > 255) {
      newErrors.name = true;
      correctSignup = false;
    }

    if (surname.length > 255) {
      newErrors.surname = true;
      correctSignup = false;
    }

    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regexEmail.test(email)) {
      newErrors.email = true;
      correctSignup = false;
    }

    const regexPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/;
    if (password.length < 8 || !regexPwd.test(password)) {
      newErrors.password = true;
      correctSignup = false;
    }

    if (password !== repassword) {
      newErrors.repassword = true;
      correctSignup = false;
    }

    if (selectedLanguage === '') {
      newErrors.language = true;
      correctSignup = false;
    }

    if (!correctSignup) {
      setErrors(newErrors);
    } else {
      // Call the API to create the user
      const correctSignup = await getUserSignup(name, surname, email, password, selectedLanguage);
      if (correctSignup.ok) {
        setClassesData([]);
        editUserAuth(correctSignup.data);
        const lang = correctSignup.data.language ? correctSignup.data.language : 'es';
        handleLanguageChange(lang);
        navigate('/classes');
      } else {
        setNotification({ msg: t(correctSignup.error), type: 'ERR' });
      }
    }
    setLoading(false);
  };

  /**
   * Handles eye icon click event.
   */
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  /**
   * Handles eye icon click event for repassword.
   */
  const toggleShowRepassword = () => {
    setShowRepassword(!showRepassword);
  }

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    handleLanguageChange(savedLanguage ? JSON.parse(savedLanguage) : 'es');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Options for language selector
  const optionsLanguage = getLanguages();

  return (
    <div className="signupDivOutside">
      <div className="signupDivBackground">
        <div className="signupDivBackgroundOutside">
          {fruitImages.map((fr) => (
            <img alt="frpos" width={'100px'} height={'100px'} src={fr.img} key={fr.id} style={{ top: fr.top, left: fr.left }} />
          ))}
        </div>
      </div>
      <div className="signupDivInside">
        <div className="signupDivImg">
          <img alt="fruitityIcon" className="signupImg" src={fruitityIcon} />
          <h1 className="menuTitle">Fruitity</h1>
        </div>
        <div className="signupDivForm">
          <h1 className="signupTitle">{t('signup')}</h1>
          <div className="signupForm">
            <div className="signupInput">
              <label htmlFor="name">{t('name')}</label>
              <input type="text" id="name" name="name" value={name} placeholder={t('name')} onChange={(e) => setName(e.target.value)} />
              {errors && errors.name && <p className="signupError pError">{t('wrongSignupName')}</p>}
            </div>

            <div className="signupInput">
              <label htmlFor="surname">{t('surname')}</label>
              <input type="text" id="surname" name="surname" value={surname} placeholder={t('surname')} onChange={(e) => setSurname(e.target.value)} />
              {errors && errors.surname && <p className="signupError pError">{t('wrongSignupSurname')}</p>}
            </div>

            <div className="signupInput">
              <label htmlFor="email">{t('email')}</label>
              <input type="text" id="email" name="email" value={email} placeholder={t('email')} onChange={(e) => setEmail(e.target.value)} />
              {errors && errors.email && <p className="signupError pError">{t('wrongSignupEmail')}</p>}
            </div>

            <div className="signupInput">
              <label htmlFor="password">{t('password')}</label>
              <div className="signupDivPassword">
                <input type={showPassword ? 'text' : 'password'} id="password" name="password" value={password} placeholder={t('password')} onChange={(e) => setPassword(e.target.value)} />
                <FontAwesomeIcon className="signupEyeIcon" icon={showPassword ? faEye : faEyeSlash} onClick={toggleShowPassword} />
              </div>
              {errors && errors.password && <p className="signupError pError">{t('wrongSignupPassword')}</p>}
            </div>

            <div className="signupInput">
              <label htmlFor="repassword">{t('repassword')}</label>
              <div className="signupDivPassword">
                <input type={showRepassword ? 'text' : 'password'} id="repassword" name="repassword" value={repassword} placeholder={t('repassword')} onChange={(e) => setRepassword(e.target.value)} />
                <FontAwesomeIcon className="signupEyeIcon" icon={showRepassword ? faEye : faEyeSlash} onClick={toggleShowRepassword} />
              </div>
              {errors && errors.repassword && <p className="signupError pError">{t('wrongSignupRepassword')}</p>}
            </div>

            <div className="signupInput">
              <label htmlFor="">{t('select_language')}:</label>
              <Selector
                options={optionsLanguage}
                selectedOption={selectedLanguage}
                onOptionChange={handleLanguageChange} />
            </div>

            <p className="signupText">{t('signup_accept_policy')}</p>

            <button onClick={handleSignup}>{t('signup')}</button>
            <button className="btnSignupGoogle" onClick={signInWithGoogle}>{t('signup-with')} <FontAwesomeIcon className="iconSignupGoogle" icon={faGoogle} /></button>

            <Link to="/login">
              <p className="signupLink">{t('alredyAccountGoToLogin')}</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
